<!-- <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="container">
    <mat-tab-group>
        <mat-tab  *ngFor="let team of lstTeamsChecklist; let indexTeam = index" [label]="team.Avatar">
            <div class="main">
                <div>
                    <div class="checklist" *ngFor="let check of team.checkList.checkList; let j=index">
                        <mat-checkbox [(ngModel)]="check.valor">{{check.title}}</mat-checkbox>
                    </div>
                </div> -->
                <!-- <div class="div-btn">
                    <button class="btn btn-outline-success" (click)="sendCheckList(indexTeam)">Send</button>
                </div> -->
                <!-- <div>
                    <button class="btn btn-outline-primary" (click)="updateChecklist()">Reload</button>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div> -->
<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="container">

    <div mat-dialog-content >
        <!-- <div class="grid-container main" >
          <div class="marginRigth style2b calibriFont" style="text-align: center;">Labels</div>
          <div class="marginRigth style2b calibriFont" style="text-align: center;" *ngFor="let jsnTeam of lstTeamsChecklist">{{jsnTeam['Avatar']}}</div>
        </div>
        <div class="grid-container main" *ngFor="let jsnLabel of lstCheckbox.labels; let indice=index">
            <div class="marginRigth style2b calibriFont" style="text-align: center;">{{jsnLabel}}</div>
            
            <div class="marginRigth style2b calibriFont" style="text-align: center;" *ngFor="let jsnCheck of lstCheckbox.checkList">
                <mat-checkbox disabled class="check" *ngIf="jsnCheck[indice]==0" ></mat-checkbox>
                <mat-checkbox disabled class="check" *ngIf="jsnCheck[indice]==1"  checked="true"></mat-checkbox>
                <mat-checkbox disabled class="check" *ngIf="jsnCheck[indice]==2" style="display: none;"></mat-checkbox>
    
            </div>
        </div> -->
        <div class="btnRefresh">
            <button class=" btn btn-primary " (click)="updateChecklist(true)" style="flex: 1; margin: 7px 42px;">Refresh</button>
            <div class="" style="text-align: center;" *ngFor="let jsnTeam of lstTeamsChecklist" >
                <button class="avatar1" (click)="addLoanToTeam(jsnTeam['TeamId'],jsnTeam['Avatar'])" [ngStyle]="{'background-color': jsnTeam['Color']?jsnTeam['Color']:'#1b3f89','color':jsnTeam['Color']=='#bec3ff' || jsnTeam['Color']=='#84eeeb' || jsnTeam['Color']=='#ffe998' || jsnTeam['Color'] == '#e4e4ec' || jsnTeam['Color'] == '#7faeff' ?'black':'white'}"> <strong>New Loan</strong> </button>
            </div>
    
        </div>
    
          <div class="grid-container main" >
            <div class="marginRigth style2b calibriFont title" style="text-align: center;"><strong>Team CheckList</strong> </div>
            <div class="marginRigth style2b calibriFont avatar" style="text-align: center;" *ngFor="let jsnTeam of lstTeamsChecklist" [ngStyle]="{'background-color': jsnTeam['Color']?jsnTeam['Color']:'#1b3f89','color':jsnTeam['Color']=='#bec3ff' || jsnTeam['Color']=='#84eeeb' || jsnTeam['Color']=='#ffe998' || jsnTeam['Color'] == '#e4e4ec' || jsnTeam['Color'] == '#7faeff' ?'black':'white'}" ><strong>{{jsnTeam['Avatar']}}</strong></div>
          </div>
          <ng-container *ngFor="let jsnLabel of labelsCheck; let indice=index">
            <div class="grid-container main" *ngIf="shouldShowRow(jsnLabel)">
                <div [ngStyle]="{'background-color':jsnLabel=='Buy or Upgrade Equipment' || jsnLabel=='Pay Recruitment & Training'  ? '#c3fec7' : '#c3d8fe',
                                 'display':(jsnLabel=='Buy or Upgrade Equipment' && lastStartMonth < 3) || (jsnLabel=='Pay Recruitment & Training' && lastStartMonth < 3) || (jsnLabel=='Select Improvement Option' && lastStartMonth < 3)   ? 'none' : 'block'}"class="marginRigth style2b calibriFont checkTitle" style="text-align: center;">{{mapCheckboxTitleToLabel(jsnLabel)}}</div>
                <!-- <div [ngStyle]="{'background-color':person.country === 'UK' ? 'green' : 'red' }"></<div> -->
                <div [ngStyle]="{'background-color':jsnLabel=='Buy or Upgrade Equipment' || jsnLabel=='Pay Recruitment & Training'  ? '#c3fec7' : '#c3d8fe'}" class="marginRigth style2b calibriFont checkContainer" style="text-align: center;" *ngFor="let jsnTeam of lstTeamsChecklist">
                  <mat-checkbox  disabled class="check" *ngIf="jsnTeam['tableCheck'][indice]['valor']==0 && jsnTeam['isMonthDone'] == false" [ngStyle]="{'display':((jsnTeam['tableCheck'][indice]['title']=='Select Improvement Option') || (jsnTeam['tableCheck'][indice]['title']=='Pay Recruitment & Training') || (jsnTeam['tableCheck'][indice]['title']=='Buy or Upgrade Equipment')) && (lastStartMonth < 3 ||  jsnTeam['tableCheck'][indice]['show'] == 0)   ? 'none' : 'block'}"></mat-checkbox>
                  <mat-checkbox disabled class="check" *ngIf="jsnTeam['tableCheck'][indice]['valor']==1 || (jsnTeam['isMonthDone'] == true && jsnTeam['tableCheck'][indice]['valor'] != 2)"  [ngStyle]="{'display':((jsnTeam['tableCheck'][indice]['title']=='Select Improvement Option') || (jsnTeam['tableCheck'][indice]['title']=='Pay Recruitment & Training') || (jsnTeam['tableCheck'][indice]['title']=='Buy or Upgrade Equipment')) && (lastStartMonth < 3 ||  jsnTeam['tableCheck'][indice]['show'] == 0)   ? 'none' : 'block'}" checked="true" ></mat-checkbox>
                  <mat-checkbox disabled class="check" *ngIf="jsnTeam['tableCheck'][indice]['valor']==2 && jsnTeam['isMonthDone'] == false" style="display: none;"></mat-checkbox>
                </div>
            </div>
          </ng-container>
        </div>
        <div>
            <mat-icon (click)="matDialogRef.close()">close</mat-icon>
        </div>
    </div>
    