import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TeamService } from 'src/app/services/team/team.service';
import { OrderService } from 'src/app/services/order/order.service';
import { SocketOrderService } from 'src/app/services/sockets/socket-order/socket-order.service';
import { finalize, find, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImprovementService } from 'src/app/services/improvement/improvement.service';
//import { timeStamp } from 'node:console';
import { ThisReceiver } from '@angular/compiler';
import { environment } from 'src/environments/environment';
import { BoardService } from 'src/app/services/board/board.service';
import { event } from 'jquery';
import { SocketioService } from 'src/app/services/socketio.service';
import { WorkshopService } from 'src/app/services/workshop/workshop.service';

export interface improvements{
  ImproveId: number;
  TeamId: number;
  WorkShopId: number;
  options: Array<options>
}

export interface options{
  ImproveId: number
  ImproveOptionId: number;
  Option: number;
  Status: number;
  TeamId: number;
  Month: number;
}
export interface Order {
  OrderId: number;
  OrderNum: number;
  Quantity: number;
  Terms: number;
  Bids: Array<Bid>;
  WiningTeam: number;
  Winers:Array<number>
  blnStatusOrder: boolean;
  Month: number;
}

export interface Bid {
  Bid: number;
  BidId: number;
  OrderId: number;
  TeamId: number;
  intStatusTeam: number;
  blnNotSupplies : boolean;
}

export interface Team {
  Avatar: string;
  Color: string;
  Mode: number;
  Month: number;
  Name: string;
  Room: any;
  Status: any;
  Supplies: number;
  TeamId: number;
  WokshopId: number;
  Advertising: number;
  Sold: number;
  Slogan: string;
  improvements: Array<any>;
  isImpQuality: boolean;
  monthQualityImplemented: number;
  numberRoyals: number;
  maxSupplies: number;
  BudgetCompleteNetIncome: number;
  historicMaxSupplies: number;
  historySupplies: number;
}
@Component({
  selector: 'app-dialog-orders',
  templateUrl: './dialog-orders.component.html',
  styleUrls: ['./dialog-orders.component.scss']
})
export class DialogOrdersComponent implements OnInit, OnDestroy {
  bidsConApuesta : number
  bidsAsignadas : number = 0;
  previusNumber = ""
  arrImprovementTeams: Array<options> = [];
  ranTeam: Team = null;
  strClassCoin = "";
  strClassRoulette = "";
  strWinnerRoulette = "";
  numbers = [];
  improvements : Array<improvements> = [];
  intOpenMarket = 0;
  cutomizingOption = false;
  //intervalId: NodeJS.Timeout;
  time = "";
  arrAllOrders: Array<Order> = [];
  arrOrders: Array<Order> = [];
  arrAllTeams: Array<Team> = [];
  arrAllTeamsAux: Array<Team> = [];
  arrAdv = [];
  blnIsFacilitator = false;
  teamId = -1;
  workShopId = -1;
  roomId = "";
  strColor;
  view = 0;
  month = 1;
  monthHistoric = 2;
  dialogRefTimer: any;
  minutes = 0;
  seconds = 0;
  blnAvalibleBid = false;
  more4;
  statusBoard = 0;
  boardMode:string;
  blnArrow: boolean = false;
  marketStep: number = 0;
  reveal = -1;
  blnEmpate = false
  hasControl = false;
  currentOrder:Order;
  CurrentCash = 0;
  CurrentAdv = 0;
  canTimer = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  toast = Swal.mixin({
    toast: true,
    customClass: {
      container: 'errorReveal',
      content: 'errorReveal'
    },
    // position: 'top',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  })
  headerDataSource = new MatTableDataSource<any>();
  dataSourceTableOfOrders: MatTableDataSource<any> = new MatTableDataSource(
    []
  );

  titulosOfOrders: string[] = [];

  titulosBidPrepMemM1: string[]  = [
    'order',
    'quantity',
    'terms',
    'bid',
    'unitprice',
  ];

  titulosBidPrepMemM2: string[]  = [
    'order',
    'quantity',
    'terms',
    'bid',
    'unitprice',
  ];

  titulosBidPrepFac: string[]  = [
    'order',
    'quantity',
    'terms',
    'team1',
    'team2',
  ];

  titulosMarket: string[]  = [
    'order',
    'quantity',
    'terms',
  ];

  colorToggles = {
    color: 'primary',
    background: 'primary',
  }

  active = 0;
  tipoBoton = ""
  finishMarket = 0
  @ViewChild('timer') timer: TemplateRef<any>;
  
  constructor(
    private teamService: TeamService,
    private orderService: OrderService,
    private socketOrder: SocketOrderService,
    private socketService: SocketioService,
    public matDialogRef: MatDialogRef<DialogOrdersComponent>,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private improvementService: ImprovementService,
    private boardService: BoardService,
    private workshopService: WorkshopService,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    console.log("this._data", this._data);
    this.view = this._data['view'];
    this.tipoBoton = this._data['tipoBoton'];
    this.blnAvalibleBid = this._data['blnAvalibleBid'];
    this.hasControl = this._data['hasControl'];
    this.blnIsFacilitator = this._data['blnIsFacilitator'];
    this.workShopId = this._data['workShopId'];
    this.boardMode = this._data['boardMode'];
    this.monthHistoric = this.month = this._data['month'];
    //if (!this.blnIsFacilitator)
    this.teamId = this._data['teamId'];
    this.statusBoard = this._data['statusBoard'];
    this.CurrentCash = this._data['Current_Cash'];
    this.CurrentAdv= this._data['Current_Adv'];
    this.fnGetImprovementOptionsByTeamId(this.teamId);
    this.numbers = Array(this._data['cycles'])//this.month);
  }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    const respOpenMarket = await this.fnGetOpenMarket(this.workShopId);
    this.fnInitView();
    const respOrders = await this.fnGetAllOrders(this.workShopId);
    console.log("ngOnInit after response");
    const respTeams = await this.fnGetAllTeams(this.workShopId, true);
    this.fnGetImprovementsByWorlkshopId(this.workShopId);
    this.fnInitSocket();
    this.fnInitSocketService();
    this.workshopService.setLogFac(this._data.teamId,this._data.userName,'Market', 'Open', this._data.month).subscribe(res =>{ }) //Log for fac
    this.boardService.getMarketStep(this.workShopId).subscribe(res => {
      if (res.intResponse === 200 && res.jsonData) {
        this.marketStep = res.jsonData.MarketStep;
        if(this.marketStep == 0){
          this.blnAvalibleBid = false;
        }else{
          if(this.marketStep == 2 && this.view != 1){
            setTimeout(() => {
              let alreadyWinners = this.arrOrders.filter(x => x.WiningTeam != 0 && x.WiningTeam != null);
              if(alreadyWinners.length == 0){
                this.fnOpenMarket();
              }
            }, 1000);
          }
        }
        if(this.marketStep == 1){
          this.blnAvalibleBid = true;
        }
      }
    });
    this.arrOrders = this.arrAllOrders.filter(el => {
      if (el.Month == this.monthHistoric)//view board
        return el;
    });
    setTimeout(() => {
      console.log(this.arrOrders,this.arrAllTeams)
      for(var i=0; i<this.arrOrders.length;i++){
        for(var j=0;j<this.arrOrders[i].Bids.length; j++){
          if(this.arrOrders[i].WiningTeam == this.arrOrders[i].Bids[j].TeamId ){
            this.arrAllTeams.forEach(item => {
              if(item.TeamId == this.arrOrders[i].WiningTeam && item.isImpQuality){
                console.log("SI SE HACE",item, this.arrOrders[i] )
                Object.assign(this.arrOrders[i].Bids[j],{BidQuality: Math.round(this.arrOrders[i].Bids[j].Bid*1.20)});
              }
            });
          }
        }
      } 
    }, 500);
    setTimeout(() => {
      if(this._data.month>this._data.cycles){
        this.fnChangeMonthHistoric(this.monthHistoric - 1);
      }
    }, 700);
    if(this.month === 1 && (this.boardMode == "NM" || this.boardMode == "SSNM") && this.tipoBoton == 'menu' && this.arrOrders[0]['Bids'][0]['TeamId'] <= 0){
      console.log("ASIGNAR ORDENES")
      this.fnAssingOrders();
    }
    this.getFinishMarket()
  }

  getValueQuality(bid:any){
    return Math.round(bid*1.20)
  }
  
  fnInitSocketService(){
    // this.socketService.setupSocketConnection(
    //   this._data.userId,
    //   this.roomId,
    //   this.workShopId
    // );
    this.socketService._coin
                .pipe(takeUntil(this.destroy$))
                .subscribe((response) => {
                  if (response) { 
                    if (response.status == "controlDisabled") {
                      console.log(response);
                      if (response.data.userId != this._data.userId) {
                        this.hasControl = false;
                      } else {
                        this.hasControl = true;
                      }
                    }

                  }
                }).unsubscribe;
  }
  fnInitView(){
    console.log(this.view);
    // modificacion demo mode en mes 1
    if(this.month === 1 && (this.boardMode == "NM" || this.boardMode == "SSNM")){
      if(this.view === 1){
        if(this.blnIsFacilitator){
          console.log("AQUI", this.tipoBoton, this.statusBoard);
          if(this.tipoBoton == 'customers'){
            if(this.statusBoard >= 30){
              this.titulosOfOrders = [...this.titulosMarket];   
            }else{
              this.titulosOfOrders = this.titulosBidPrepMemM1;
            }
          }else if(this.tipoBoton == 'menu'){
            // this.titulosOfOrders = this.titulosBidPrepFac;
            this.view =2;
            this.titulosOfOrders = [...this.titulosMarket];
            this.arrAllTeams.forEach(team => this.titulosOfOrders.push(team['Avatar']));
            console.log(this.titulosOfOrders)
            console.log(this.arrAllTeams)
            //this.titulosOfOrders.push('winingTeam');
          }
        }else{
          if(this.intOpenMarket !== 2){
            console.log("AQUI");
            this.titulosOfOrders = this.titulosBidPrepMemM1;
          }else  {
            console.log("AQUI");
            this.titulosOfOrders = [...this.titulosMarket];   
          }
        }
      }
      else{
        console.log("AQUI");
        this.titulosOfOrders = [...this.titulosMarket];
      }
    }else if(this.month >= 2 || (this.month === 1 && this.boardMode === 'DM')){
      if(this.blnIsFacilitator){
        if(this.tipoBoton == 'customers'){
          if(this.intOpenMarket !== 2){
            console.log("AQUI");
            this.titulosOfOrders = this.titulosBidPrepMemM2;
          }
          else{
            console.log("AQUI");
            this.titulosOfOrders = [...this.titulosMarket];
          }
        }else if(this.tipoBoton == 'menu'){
          console.log("AQUI");
          // this.titulosOfOrders = this.titulosBidPrepFac;
          this.view = 2;
          this.titulosOfOrders = [...this.titulosMarket];
          this.arrAllTeams.forEach(team => this.titulosOfOrders.push(team['Avatar']));
          //this.titulosOfOrders.push('winingTeam');
        }
      }
      else{
        if(this.intOpenMarket !== 2){
          console.log("AQUI");
          this.titulosOfOrders = this.titulosBidPrepMemM2;
        }
        else{
          console.log("AQUI");
          this.titulosOfOrders = [...this.titulosMarket];
        }
      }
    }
  }

  fnInitSocket() {
    this.roomId = "roomWorkShop" + this.workShopId;
    this.socketOrder.closeListeners();
    this.socketOrder.setupSocketConnection(
      this.teamId,
      this.roomId
    );
    // observador para el socket que controla las monedas
    this.socketOrder._order
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (response) => {
        if (response) {
          console.log('RESPUESTAAAAAA', response);
          if (response.status == 'updateBidServer' && response.data != undefined) {
            this.fnSetBid(response.data);
          }
          if (response.status == 'timerServer' &&response.data != undefined) {
            this.fnGetTime(response.data.time);
          }
          if (response.status == 'openMarketServer' &&response.data != undefined) {
            console.log("openMarketServer",response.data);
            this.fnDisableBets();
            this.socketOrder.openMarket$(response.data['blnOpen']);
            if(response.data['blnOpen'] === 2)
              this.fnCloseDialog();
          }
          if (response.status == 'setTeamSloganServer' &&response.data != undefined) {
            console.log("setTeamSloganServer",response.data);
            this.fnSetTeamSlogan(response.data['teamId'], response.data['strTeamSlogan'])
          }
          if (response.status == 'updateMarketStep' &&response.data != undefined) {
            console.log("updateMarketStep",response.data);
            if(response.data.marketStep == 1){
              this.blnAvalibleBid = true; 
              if(this.blnIsFacilitator){
                this.arrOrders.forEach(order => {
                  order.WiningTeam = 0;
                  order.Bids.forEach(bid => {
                    bid.blnNotSupplies = false;
                    bid['isWithdrawn'] = false;
                  });
                 });
              }
            }
            if(response.data.marketStep == 2){
              if(this.blnIsFacilitator){
                this.setOrders()
              }
            }
            this.marketStep = response.data.marketStep

          }
          if (response.status == 'updateOrdersTeamsServer' &&response.data != undefined) {
            console.log("updateOrdersTeamsServer INIT",response.data);
            this.arrOrders = response.data;
            console.log(this.arrOrders)
            this.dataSourceTableOfOrders = new MatTableDataSource(
              this.arrOrders
            );
          }
          if (response.status == 'updateSuppliesServer' &&response.data != undefined) {
            this.spinner.show();
            const x = await this.fnGetAllTeams(this.workShopId, false);
            this.spinner.hide();
          }
        }
      })
  }

  async setOrders(){
    this.arrAllTeams.forEach(team => team.Sold = 0);
    this.arrOrders.forEach(order => {
      //order.WiningTeam = 0
      order.Bids.forEach(bid=> {bid.blnNotSupplies = false
      });

    });
    if(this.month > 1 || this.boardMode == "DM"){
      for (const jsonOrder of this.arrOrders) {
        let response = await this.fnSetWiningTeam(jsonOrder.OrderId);
        console.log("WINNEEEEERS tmp",response);
        if(response === true) {
          this.blnEmpate = true;
          break;
        };
      }
      console.log("ORDERS final",this.arrOrders);
      this.arrOrders = this.arrAllOrders.filter(el => {
        if (el.Month == this.monthHistoric)//view board
          return el;
      });
      for(var i=0; i<this.arrOrders.length;i++){
        for(var j=0;j<this.arrOrders[i].Bids.length; j++){
          if(this.arrOrders[i].WiningTeam == this.arrOrders[i].Bids[j].TeamId ){
            this.arrAllTeams.forEach(item => {
              if(item.TeamId == this.arrOrders[i].WiningTeam && item.isImpQuality){
                Object.assign(this.arrOrders[i].Bids[j],{BidQuality: Math.round(this.arrOrders[i].Bids[j].Bid*1.20)});
              }
            });
          }
        }
      } 
    }else{
      this.fnSetWiningTeamMonth1();
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();


  }

  async fnSetBid(data:any){
    console.log("fnSetBid",data);
    for(var order of this.arrOrders) {
      if(order.OrderId === data['orderId']) {
        for(let bid of order.Bids) {
          if(bid.BidId === data['bidId']) {
            bid.Bid = data['bid'];
            break;
          }
        }
      }
    }
    const advertising = await  this.arrAllTeams.forEach((team,index) => this.fnSetAdvertisingSpend(team.TeamId,index));
    console.log("fnSetBid fnSetAdvertisingSpend");
    console.log("fnSetBid",this.arrOrders);
    this.dataSourceTableOfOrders = new MatTableDataSource(
      this.arrOrders
    );
  }



  fnGetAllTeams(workShopId, pushTitulos) {
    return new Promise(async resolve => {
      if (this.statusBoard == 29) {
        this.teamService.getAssignDefaultTeamSetUp(workShopId, this._data.teamId, this._data.blnIsFacilitator).toPromise().then(async res => {
          const x = await this.fnGetAllTeamsWorkshop(workShopId, pushTitulos);
          resolve(x);
        });
      } else {
        const x = await this.fnGetAllTeamsWorkshop(workShopId, pushTitulos);
        resolve(x);
      }
    })
  }

  fnGetAllTeamsWorkshop(workShopId, pushTitulos){
    return new Promise((resolve, reject) => {
      this.teamService.getAllTeamsById(workShopId).pipe(finalize(()=>this.spinner.hide())).subscribe(async resp => {
        console.log("fnGetAllTeamsAndMembers", resp);
        this.arrAllTeams = resp['teams'];
        this.arrAllTeams.forEach(async item => {
          item.historicMaxSupplies = 0;
          const response = await this.boardService.getActionsBoardByTeamId(item.TeamId).toPromise();
          item.maxSupplies = 4;
          let isImpQual = false;
          let jsonActionsBoard={isEfficiency1: false,isSecondMachine: false, isEfficiency2: false };
          item.improvements.forEach(imp => {
            if(imp.Option == 4 && imp.Status == 3){
              isImpQual = true;
              item.monthQualityImplemented = imp.Month;
            }
          });
          item.isImpQuality = isImpQual;
          response.response.forEach(element => {
            if(element.Name == "isEfficiency1" || element.Name == 'isSecondMachine' || element.Name == 'isEfficiency2'){
              jsonActionsBoard[element.Name] = true;
            }
          });
          if(jsonActionsBoard.isSecondMachine && jsonActionsBoard.isEfficiency1 && jsonActionsBoard.isEfficiency2){
            // 2 maquinas de 6 c/u
            item.maxSupplies = 12;
          } else if(jsonActionsBoard.isSecondMachine && (jsonActionsBoard.isEfficiency1 || jsonActionsBoard.isEfficiency2)){
            // 2 maquinas una de 6 y otra de 4
            item.maxSupplies = 10;
          }else if(jsonActionsBoard.isSecondMachine && !jsonActionsBoard.isEfficiency1 && !jsonActionsBoard.isEfficiency2){
            // 2 maquinas de 7 c/u
            item.maxSupplies = 8;
          }else if(!jsonActionsBoard.isSecondMachine && (jsonActionsBoard.isEfficiency1 || jsonActionsBoard.isEfficiency2)){
            // 1 maquinas una de 6
            item.maxSupplies = 6;
          }else if(!jsonActionsBoard.isSecondMachine && !jsonActionsBoard.isEfficiency1 && !jsonActionsBoard.isEfficiency2){
            // 2 maquinas una de 6 y otra de 4
            item.maxSupplies = 4;
          }
          item.maxSupplies += item.numberRoyals ? item.numberRoyals : 0;
          item.historySupplies = 0;
        });
        const teamFacilitator = await this.fnGetTeamFacilitator(this.teamId);
        console.log("teamFacilitator", teamFacilitator)
        this.arrAllTeamsAux = resp['teams'].concat(teamFacilitator);
        console.log("this.arrAllTeamsAux",this.arrAllTeamsAux);
        this.arrAllTeams.forEach(team => {
          if(team.TeamId === this.teamId)
            this.strColor = team.Color;
        });
        //if(this.blnIsFacilitator && this.month > 2) this.fnGetImprovementTeams(); OBTENER LOS IMPROVEMENTS DE TODOS LOS TEAMS Y VALIDAR CONTRA LAS ORDENES GANADAS EN HISTORICO PARA 20% MAS EN BID
        //if((this.view >= 2 || this.intOpenMarket === 2) && !this.blnIsFacilitator)
        if((this.blnIsFacilitator && this.month > 1) || (this.blnIsFacilitator && this.month === 1 && this.view > 1) || ((this.view >= 2 || this.intOpenMarket === 2) && !this.blnIsFacilitator)){
          if(pushTitulos){
            console.log("aqui")   
            if(this.blnIsFacilitator && this.month > 1 && this.tipoBoton == 'customers' && this.intOpenMarket !== 2){
              console.log("Caso especial");
              this.titulosOfOrders = [];
              this.titulosOfOrders = this.titulosBidPrepMemM2;
            }else{
              this.arrAllTeams.forEach(team => this.titulosOfOrders.push(team['Avatar']));
              this.titulosOfOrders.push('winingTeam');
  
            }
          }
        }
        if(this.blnIsFacilitator && this.month == 1 && this.statusBoard >= 30 && this.tipoBoton == 'customers' && this.intOpenMarket == 2){
          console.log("Caso especial");

          this.arrAllTeams.forEach(team => this.titulosOfOrders.push(team['Avatar']));
          this.titulosOfOrders.push('winingTeam');
        }else if(this.blnIsFacilitator && this.month == 1 && this.statusBoard >= 30 && this.tipoBoton == 'customers' && this.intOpenMarket != 2){
          this.titulosOfOrders = this.titulosBidPrepMemM1;
        }

        this.arrAllTeams.forEach((team,index) => {
          this.fnSetAdvertisingSpend(team.TeamId,index);
          this.fnSold(team.TeamId,index);
        });
        console.log("this.arrAllTeams",this.arrAllTeams);
        resolve('');
      });
    });
  }

  fnGetImprovementTeams(){
    for (const team of this.arrAllTeams) {
      this.improvementService.getImprovementOptionsByTeamId(team.TeamId).subscribe(response=>{
        this.arrImprovementTeams.concat(...response['options']);
      })
    }
  }

  fnGetTeamFacilitator(teamId){
    return new Promise(resolve=>{
      this.boardService.getFacilitatorIdTeamByTeamId(teamId).subscribe(respBoard =>{
        console.log(respBoard)
        if(respBoard['intResponse'] == 200){
          this.teamService.getTeamById(respBoard['teamId']['TeamId']).subscribe(resp=>{
            console.log("TEAM FACILITATOR", resp);
            resolve(resp['team']);
          })
        }
        
      })
      
    })
  }

  fnGetAllOrders(workShopId) {
    console.log("fnGetAllOrders");
    return new Promise(resolve=>{
      this.orderService.getOrdersByWorkShopId(workShopId).subscribe(resp => {
        console.log("fnGetAllOrders", resp);
        this.arrAllOrders = resp['orders'];
        this.arrOrders = resp['orders'].filter(el => {
          el.Winers = [];
          if (el.Month == this.month)//view board
            return el;
        });
        this.arrOrders.forEach(order => {
          order.blnStatusOrder = false;
          if(order.WiningTeam){
            //this.blnArrow = true;
          }
          //order.Winers = [];
          order.Bids.push({Bid:0,BidId:0,OrderId:order.OrderId,TeamId:0,intStatusTeam:0,blnNotSupplies:false});
          order.Bids.forEach(bid =>{ bid.intStatusTeam = 0})
        });
        console.log("this.arrOrders",this.arrOrders);
        console.log("this.ALLarrOrders",this.arrAllOrders,this.view);
        this.dataSourceTableOfOrders = new MatTableDataSource(
          this.arrOrders
        );
        resolve('');
      })
    })
  }

  async fnChangeBid(orderId, bid: Bid, row) {
    //obtenemos la cantidad de advertising actual
    console.log(event);
    if(this.blnIsFacilitator){
      return
    }
    console.log("aqui",orderId,bid,row)
    var countAdvCurrent = 0;
    this.arrOrders.forEach(order => {
      order.Bids.forEach(Orderbid =>{
        if((Orderbid.Bid > 0 && Orderbid.TeamId === bid.TeamId))
        countAdvCurrent++;
      })
    });
    countAdvCurrent = countAdvCurrent - this.CurrentAdv;

    if (bid.Bid === null)
      bid.Bid = 0;
    if (!this.blnIsFacilitator)
    {
      console.log(countAdvCurrent, this.CurrentCash, this.CurrentAdv)

      //DCM si el contador de bids es mayor a la cantidad de cash debemos indicarle que no es posible hacerlo
      if(bid.Bid == 0){
        this.socketOrder.updateBid(this.roomId, orderId, bid.Bid, bid.BidId);
        let index = this.arrAllTeams.findIndex(team => team.TeamId === bid.TeamId);
        const advertising = await this.fnSetAdvertisingSpend(bid.TeamId, index);
       }
      else if(countAdvCurrent <= this.CurrentCash){
        this.socketOrder.updateBid(this.roomId, orderId, bid.Bid, bid.BidId);
        let index = this.arrAllTeams.findIndex(team => team.TeamId === bid.TeamId);
        const advertising = await this.fnSetAdvertisingSpend(bid.TeamId, index);
       }
      else{
        const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === '6027');
          Swal.fire({
            text: '',
            imageUrl: '../../../../assets/iconPrevention.svg',
            imageAlt: 'icon info',
            confirmButtonColor: '#6FB45C',
            showCancelButton: false,
            showConfirmButton: true,
            html: tmp.Label,
          });
          bid.Bid = 0;
          this.socketOrder.updateBid(this.roomId, orderId, bid.Bid, bid.BidId);
          let index = this.arrAllTeams.findIndex(team => team.TeamId === bid.TeamId);
          const advertising = await this.fnSetAdvertisingSpend(bid.TeamId, index);
        }
      
    }
    //let index = this.arrAllTeams.findIndex(team => team.TeamId === bid.TeamId);
    //const advertising = await this.fnSetAdvertisingSpend(bid.TeamId, index);
  }

  fnAlertBid(){
    Swal.fire({
      text: '',
      icon: 'error',
      confirmButtonColor: '#6FB45C',
      html: `<label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;"> 109.- Review your bid! </label>`,
    });
  }

  fnSetWiningTeam(orderId){
    return new Promise(async resolve =>{
    console.log("vamo a asignar ganador para la orden:", orderId);
      console.log("fnSetWiningTeam this.arrOrders",this.arrOrders );
      console.log("this.arrAllTeams::",this.arrAllTeams);
      let bestBid = 0;
      let countWinners = 0;
      let winnerTeam = this.teamId;
      let team:Team;
      let teamIndex:number;
      let team2:Team;
      let nextIf = true;
      if(this.month > 1 || this.boardMode == "DM")
        for (let [index, order] of this.arrOrders.entries()) {
          //console.log("ORDER",index,order.OrderNum, order);
          if(order.OrderId === orderId){
            console.log("ORDEN::::::::::::::::::::::::::",orderId.toString());
            console.log("Aumento ACTUAL countWinners:",countWinners.toString());
            for (let [ind, bid] of order.Bids.entries()) {
              team = this.arrAllTeams.find(team=> team.TeamId === bid.TeamId);
              //valida que no sea inválida, null, o exceda el precio y que sea mayor igual a 0
              if(team !== undefined && (bid.Bid/order.Quantity) <= (16 - this.month) && (order.WiningTeam !== team.TeamId ? (team.Supplies - (team.Sold + order.Quantity)) : (team.Supplies - team.Sold)) >= 0){
                console.log("bid valida: ",bid.Bid.toString()," team.TeamId:",team.TeamId.toString());
                nextIf = true;
                if(bestBid === 0){
                  console.log("entra bestBid === 0");
                  bestBid = bid.Bid;
                  winnerTeam = bid.TeamId;
                  nextIf = false;
                  order.Winers = [bid.TeamId];//asigan el actual ganador
                  console.log("order.Winers:",order.Winers.toString());
                }else if(this.month >= 3 && bid.Bid > 0){
                  console.log("SET WINER ENTRA A QUALITY");
                  let qualityOptionTeam = await this.fnGetQualityOption(bid.TeamId);
                  let qualityOptionTeam2 = await this.fnGetQualityOption(winnerTeam);
                  console.log("bid.TeamId Q", qualityOptionTeam, bid.TeamId);
                  console.log("winnerTeam Q", qualityOptionTeam2, winnerTeam);
                  console.log("bid.Bid === bestBid:",bid.Bid, bestBid);
                  //Si las bids son iguales y el equipo ganadorActual no tiene quality 
                  //y el siguiente equipo sí tiene quality le asigna la bid al nuevo equipo
                  if(!qualityOptionTeam2 && qualityOptionTeam && (bid.Bid === bestBid && bid.Bid > 0)){
                    bestBid = bid.Bid;
                    winnerTeam = bid.TeamId;
                    order.Winers = [bid.TeamId];//reemplaza el actual ganador
                    nextIf = false;
                    countWinners = 0;
                    console.log("order.Winers y  bestBid",order.Winers.toString(), bestBid);
                    console.log("Asigna false nextIf::",nextIf);
                  }
                  //Si las bids son iguales y el equipo ganadorActual sí tiene quality 
                  //y el siguiente equipo no tiene quality se sigue quedando el actual ganador
                  if(qualityOptionTeam2 && !qualityOptionTeam && (bid.Bid === bestBid && bid.Bid > 0)){
                    /*bestBid = bid.Bid;
                    winnerTeam = bid.TeamId;
                    order.Winers = [bid.TeamId];*/
                    nextIf = false;
                    console.log("order.Winers y  bestBid",order.Winers.toString(), bestBid);
                    console.log("Se queda mismo ganador Asigna false nextIf::",nextIf);
                  }
                  else if((qualityOptionTeam2 && qualityOptionTeam) || (!qualityOptionTeam2 && !qualityOptionTeam)){
                    nextIf = true;
                    console.log("Asigna true nextIf::",nextIf);
                  }
                }
                console.log("nextIf antes ifbid menor::",nextIf)
                if(bid.Bid < bestBid && bid.Bid > 0 && nextIf)
                {
                  console.log("entra bid.Bid ",bid.Bid.toString()," < ",bestBid.toString()," && bid.Bid > 0 && nextIf:", nextIf);
                  bestBid = bid.Bid;
                  winnerTeam = bid.TeamId;
                  order.Winers = [bid.TeamId];//reemplaza el actual ganador
                  countWinners = 0 ;//reseteamos si anteriormente hubo ganadores porque alguien ya gano por advertising
                    console.log("Reseteó countWinners:",countWinners.toString());
                  console.log("order.Winers:",order.Winers.toString());
                }else if(bid.Bid === bestBid && bid.Bid > 0 && nextIf){
                  console.log("entra bid.Bid ",bid.Bid.toString(),bestBid.toString()," === bestBid && bid.Bid > 0 && nextIf:", nextIf);
                  team = this.arrAllTeams.find(team=> team.TeamId === bid.TeamId);
                  team2 = this.arrAllTeams.find(team=> team.TeamId === winnerTeam);
                  let AdvertisingTeam1 = await this.fnGetTeamAdvertising(team.TeamId, (this.arrOrders.length - 1));
                  let AdvertisingTeam2 = await this.fnGetTeamAdvertising(team2.TeamId, (this.arrOrders.length - 1));
                  console.log("AdvertisingTeam1:",AdvertisingTeam1.toString(), " team.TeamId",team.TeamId.toString());
                  console.log("AdvertisingTeam2:",AdvertisingTeam2.toString(), "actual winner team.TeamId",team2.TeamId.toString());
                  if(AdvertisingTeam1 > AdvertisingTeam2){
                    bestBid = bid.Bid;
                    winnerTeam = bid.TeamId;
                    order.Winers = [bid.TeamId];
                    countWinners = 0 ;//reseteamos si anteriormente hubo ganadores porque alguien ya gano por advertising
                    console.log("Reseteó countWinners:",countWinners.toString());
                    console.log("adv1 > adv 2", order.Winers.toString());
                  }
                  else if(AdvertisingTeam1 === AdvertisingTeam2)
                  {countWinners++;
                    order.Winers.push(bid.TeamId);
                    console.log("Aumento countWinners:",countWinners.toString());
                    console.log("push adv1 === adv 2", order.Winers.toString());
                  }
                }
              }else if(team !== undefined && (order.WiningTeam !== team.TeamId ? (team.Supplies - team.Sold - order.Quantity) : (team.Supplies - team.Sold)) < 0){
                console.log("TEST bid no valida: teams.teamID:",team.TeamId,"order.WinningTeam:", order.WiningTeam, "order.Quantity", order.Quantity, "team.Supplies - team.Sold:", team.Supplies, team.Sold  );
                bid.blnNotSupplies = true;
                //order.Winers = [];
              }else if(team !== undefined && (bid.Bid/order.Quantity) >= (16 - this.month)){
                console.log("ES MAYOOOOOR");
                bid['isWithdrawn'] = true;

              }
            }
            console.log("analisar val al termino coutwINNS  bestbids:", countWinners, " ", bestBid)
            if(countWinners > 0){
              console.log("countWinners > 0",countWinners.toString())
              order.WiningTeam = null;
              resolve(true);
            }else if(bestBid === 0){
              console.log("bestBid === 0",countWinners.toString())
              order.WiningTeam = this.teamId;
              order.Winers = []
              resolve(false);
            }else{
              order.Winers = []
              order.WiningTeam = winnerTeam;
              teamIndex = this.arrAllTeams.findIndex(team=> team.TeamId === winnerTeam);
              //console.log("fnSold",winnerTeam);
              const response2 = await this.fnSold(order.WiningTeam, teamIndex);
              console.log("else: winnerTeam",winnerTeam.toString()," teamIndex:",teamIndex.toString(), " order.WiningTeam", order.WiningTeam.toString(),);
              resolve(false);
            }            
            break;
          }
        }
    })
  }

  fnSetWiningTeamMonth1() {
    let i = 0;
    const arrWinners: number[] = [];
    this.arrOrders.forEach(order => {
      if (i < this.arrAllTeams.length) {
        // Asigna un equipo solo si el índice es válido
        const teamId = this.arrAllTeams[i].TeamId;
        order.WiningTeam = teamId;
        order.Bids.forEach(bid => {
          if (bid.BidId !== 0 && arrWinners.includes(bid.TeamId)) {
            bid.blnNotSupplies = true;  // Marca las apuestas como no válidas si el equipo ya ganó
          }
        });
        arrWinners.push(teamId);
      } else {
        // Marca las órdenes sobrantes sin equipo ganador
        order.WiningTeam = this.teamId // Se supone que es el id del team del fac :)
        order.Bids.forEach(bid => {
          bid.blnNotSupplies = true;  // Marca las apuestas como no válidas
        });
      }
      i++;  // Simplemente incrementamos el índice
    });
  }

  fnGetTeamAdvertising(teamId, index){
    return new Promise(resolve=>{
      let count = 0;
      for(let i = 0; i <= index; i++){
        this.arrOrders[i].Bids.forEach(bid =>{
          if((bid.Bid > 0 && bid.TeamId === teamId))
          count++;
        });
      }
      resolve(count);
    })
  }

  fnUnitPrice(bid:number){
      return bid % 1 ===0 ? bid : bid.toFixed(2);
  }

  fnSold(teamId,i){
    return new Promise(resolve=>{
      
      let count = 0;
      this.arrOrders.forEach(order => {
        if((order.WiningTeam === teamId))
          count += order.Quantity;
      });
      //return count;
      this.arrAllTeams[i].Sold = count;
      console.log("fnSold asigan:", this.arrAllTeams[i].toString(), "count:",count.toString());
      resolve("resolved");
    });
  }

  fnRevealSumSold(teamId,orderNum){
    let count = 0;
    this.arrOrders.forEach(order => {
      if((order.WiningTeam === teamId) && order.OrderNum <= orderNum && order.blnStatusOrder === true)
        count += order.Quantity ;
    });
    let team = this.arrAllTeams.find(team => team.TeamId === teamId);
    team.Sold = count;
    console.log("aqui")

  }
  checkWinner(teamId,orderId){
    //console.log(teamId,orderId)
    let team;
    if(this.currentOrder == undefined){
      team = this.arrOrders.find(teamI => teamI.WiningTeam == teamId && teamI.OrderId == orderId)
    }else{
      team = this.arrOrders.find(teamI => teamI.WiningTeam == teamId && teamI.OrderId == orderId && teamI.blnStatusOrder == true)
    }
    if(team != undefined){
      return true;
    }
  }
  fnSetAdvertisingSpend(teamId,i){
    console.log("fnSetAdvertisingSpend");
    if ((this.statusBoard == 0 || this.statusBoard == -1) && !this.blnIsFacilitator) { //Validacion para mostrar solamente el Adv, sin hacer modificaciones a la moneda - Card: BUG: Advertising Spend on Historical Markets
      let count = 0;
      this.arrOrders.forEach(order => {
        order.Bids.forEach(bid => {
          if ((bid.Bid > 0 && bid.TeamId === teamId))
            count++;
        })
      });
      console.log(count);
      this.arrAllTeams[i].Advertising = count;
      return;
    }
    let count = 0;
    this.arrOrders.forEach(order => {
      order.Bids.forEach(bid =>{
        if((bid.Bid > 0 && bid.TeamId === teamId))
        count++;
      })
    });
    //return count;
    this.arrAllTeams[i].Advertising = count;
    if(this.month == this.monthHistoric)
      this.socketOrder.updateAdvertising$(teamId, count, this._data.userId);
    return false;
  }
  // async fnClickWiningTeam(order: Order, teams: Array<Team>){
  //   console.log(order, teams)
  // }
  async fnSelectWiningTeam(order:Order) {
    order.Winers = [];
    this.blnEmpate = false;
    console.log(this.arrOrders)
    this.arrOrders.forEach(ord=>{
      if(ord.OrderNum >= order.OrderNum){
        if(ord.OrderNum > order.OrderNum) {
          ord.WiningTeam = 0;
          ord.Bids.forEach(bid=>{
            if(bid.blnNotSupplies == true){
              bid.blnNotSupplies = false;
              bid.intStatusTeam = 0;
            }
          })

        }
        ord.Bids.forEach(bid=>{
          if(bid.blnNotSupplies != true){
            bid.blnNotSupplies = false;
            bid.intStatusTeam = 0;
          }
        })
      }
    })
    console.log("fnSelectWiningTeam");
    for (const [index, team] of this.arrAllTeams.entries()) {
      const responseSold = await this.fnSold(team.TeamId,index);
    }
    this.fnOrdersWiningTeam();
    //this.arrAllTeams.forEach((team,index) => this.fnSold(team.TeamId,index));
  }

  desFocus(orderId, bid: Bid, row){
    console.log(bid)
    if(this.month == 1 && bid['Bid'] != 40 && (this.boardMode == "NM" || this.boardMode == "SSNM")){
      console.log("NO ES 40!!");
      bid['Bid'] = 0;
      this.fnChangeBid(orderId,bid,row);
      Swal.fire({
        text: '',
        imageUrl: '../../../../assets/iconPrevention.svg',
        imageAlt: 'icon info',
        confirmButtonColor: '#6FB45C',
        showCancelButton: false,
        showConfirmButton: true,
        html: this.getErrorLabel('2000'),
      });
    }else{
      this.fnChangeBid(orderId,bid,row);
    }
  }
  
  fnAssingOrders(){
    this.spinner.show();
    console.log("fnAssingOrders", this.arrOrders, this.view , this.arrAllTeams);
    if(this.blnIsFacilitator){
      if(this.view === 2){
          let arrOrdersAux = this.arrOrders
          if(this.arrAllTeams.length == 2){
            //Bids para equipo 1 - 101 104;
            arrOrdersAux[0]['Bids'][0]['TeamId'] = this.arrAllTeams[0]['TeamId'];
            arrOrdersAux[3]['Bids'][1]['TeamId'] = this.arrAllTeams[0]['TeamId'];
            //Bids para equipo 2 - 101 102;
            arrOrdersAux[1]['Bids'][0]['TeamId'] = this.arrAllTeams[1]['TeamId'];
            arrOrdersAux[0]['Bids'][1]['TeamId'] = this.arrAllTeams[1]['TeamId'];
            // //Bids para equipo 3 - 102 103;
            // arrOrdersAux[2]['Bids'][0]['TeamId'] = this.arrAllTeams[2]['TeamId'];
            // arrOrdersAux[1]['Bids'][1]['TeamId'] = this.arrAllTeams[2]['TeamId'];
            // //Bids para equipo 4 - 103 104;
            // arrOrdersAux[3]['Bids'][0]['TeamId'] = this.arrAllTeams[3]['TeamId'];
            // arrOrdersAux[2]['Bids'][1]['TeamId'] = this.arrAllTeams[3]['TeamId'];
            console.log(arrOrdersAux);
            this.orderService.updateOrdersTeamsByWorkShopId(arrOrdersAux, this.roomId)
              .pipe(finalize(() => this.spinner.hide())).subscribe(resp => {
                console.log("updateOrdersTeamsByWorkShopId", resp);
                this.fnAlertSuccess('The orders have been assigned');
              });
          }
          if(this.arrAllTeams.length == 3){
            //Bids para equipo 1 - 101 104;
            arrOrdersAux[0]['Bids'][0]['TeamId'] = this.arrAllTeams[0]['TeamId'];
            arrOrdersAux[3]['Bids'][1]['TeamId'] = this.arrAllTeams[0]['TeamId'];
            //Bids para equipo 2 - 101 102;
            arrOrdersAux[1]['Bids'][0]['TeamId'] = this.arrAllTeams[1]['TeamId'];
            arrOrdersAux[0]['Bids'][1]['TeamId'] = this.arrAllTeams[1]['TeamId'];
            //Bids para equipo 3 - 102 103;
            arrOrdersAux[2]['Bids'][0]['TeamId'] = this.arrAllTeams[2]['TeamId'];
            arrOrdersAux[1]['Bids'][1]['TeamId'] = this.arrAllTeams[2]['TeamId'];
            // //Bids para equipo 4 - 103 104;
            // arrOrdersAux[3]['Bids'][0]['TeamId'] = this.arrAllTeams[3]['TeamId'];
            // arrOrdersAux[2]['Bids'][1]['TeamId'] = this.arrAllTeams[3]['TeamId'];
            console.log(arrOrdersAux);
            this.orderService.updateOrdersTeamsByWorkShopId(arrOrdersAux, this.roomId)
              .pipe(finalize(() => this.spinner.hide())).subscribe(resp => {
                console.log("updateOrdersTeamsByWorkShopId", resp);
                this.fnAlertSuccess('The orders have been assigned');
              });
          }
          if(this.arrAllTeams.length == 4){
            //Bids para equipo 1 - 101 104;
            arrOrdersAux[0]['Bids'][0]['TeamId'] = this.arrAllTeams[0]['TeamId'];
            arrOrdersAux[3]['Bids'][1]['TeamId'] = this.arrAllTeams[0]['TeamId'];
            //Bids para equipo 2 - 101 102;
            arrOrdersAux[1]['Bids'][0]['TeamId'] = this.arrAllTeams[1]['TeamId'];
            arrOrdersAux[0]['Bids'][1]['TeamId'] = this.arrAllTeams[1]['TeamId'];
            //Bids para equipo 3 - 102 103;
            arrOrdersAux[2]['Bids'][0]['TeamId'] = this.arrAllTeams[2]['TeamId'];
            arrOrdersAux[1]['Bids'][1]['TeamId'] = this.arrAllTeams[2]['TeamId'];
            //Bids para equipo 4 - 103 104;
            arrOrdersAux[3]['Bids'][0]['TeamId'] = this.arrAllTeams[3]['TeamId'];
            arrOrdersAux[2]['Bids'][1]['TeamId'] = this.arrAllTeams[3]['TeamId'];
            console.log(arrOrdersAux);
            this.orderService.updateOrdersTeamsByWorkShopId(arrOrdersAux, this.roomId)
              .pipe(finalize(() => this.spinner.hide())).subscribe(resp => {
                console.log("updateOrdersTeamsByWorkShopId", resp);
                this.fnAlertSuccess('The orders have been assigned');
              });
          }
          if(this.arrAllTeams.length == 5){
            //Bids para equipo 1 - 101 105;
            arrOrdersAux[0]['Bids'][0]['TeamId'] = this.arrAllTeams[0]['TeamId'];
            arrOrdersAux[4]['Bids'][1]['TeamId'] = this.arrAllTeams[0]['TeamId'];
            //Bids para equipo 2 - 101 102;
            arrOrdersAux[1]['Bids'][0]['TeamId'] = this.arrAllTeams[1]['TeamId'];
            arrOrdersAux[0]['Bids'][1]['TeamId'] = this.arrAllTeams[1]['TeamId'];
            //Bids para equipo 3 - 102 103;
            arrOrdersAux[2]['Bids'][0]['TeamId'] = this.arrAllTeams[2]['TeamId'];
            arrOrdersAux[1]['Bids'][1]['TeamId'] = this.arrAllTeams[2]['TeamId'];
            //Bids para equipo 4 - 103 104;
            arrOrdersAux[3]['Bids'][0]['TeamId'] = this.arrAllTeams[3]['TeamId'];
            arrOrdersAux[2]['Bids'][1]['TeamId'] = this.arrAllTeams[3]['TeamId'];
            //Bids para equipo 5 - 104 105;
            arrOrdersAux[4]['Bids'][0]['TeamId'] = this.arrAllTeams[4]['TeamId'];
            arrOrdersAux[3]['Bids'][1]['TeamId'] = this.arrAllTeams[4]['TeamId'];
            console.log(arrOrdersAux);
            this.orderService.updateOrdersTeamsByWorkShopId(arrOrdersAux, this.roomId)
              .pipe(finalize(() => this.spinner.hide())).subscribe(resp => {
                console.log("updateOrdersTeamsByWorkShopId", resp);
                this.fnAlertSuccess('The orders have been assigned');
              });
          }
          if(this.arrAllTeams.length == 6){
            //Bids para equipo 1 - 101 106;
            arrOrdersAux[0]['Bids'][0]['TeamId'] = this.arrAllTeams[0]['TeamId'];
            arrOrdersAux[5]['Bids'][1]['TeamId'] = this.arrAllTeams[0]['TeamId'];
            //Bids para equipo 2 - 101 102;
            arrOrdersAux[1]['Bids'][0]['TeamId'] = this.arrAllTeams[1]['TeamId'];
            arrOrdersAux[0]['Bids'][1]['TeamId'] = this.arrAllTeams[1]['TeamId'];
            //Bids para equipo 3 - 102 103;
            arrOrdersAux[2]['Bids'][0]['TeamId'] = this.arrAllTeams[2]['TeamId'];
            arrOrdersAux[1]['Bids'][1]['TeamId'] = this.arrAllTeams[2]['TeamId'];
            //Bids para equipo 4 - 103 104;
            arrOrdersAux[3]['Bids'][0]['TeamId'] = this.arrAllTeams[3]['TeamId'];
            arrOrdersAux[2]['Bids'][1]['TeamId'] = this.arrAllTeams[3]['TeamId'];
            //Bids para equipo 5 - 104 105;
            arrOrdersAux[4]['Bids'][0]['TeamId'] = this.arrAllTeams[4]['TeamId'];
            arrOrdersAux[3]['Bids'][1]['TeamId'] = this.arrAllTeams[4]['TeamId'];
            //Bids para equipo 5 - 105 106;
            arrOrdersAux[5]['Bids'][0]['TeamId'] = this.arrAllTeams[5]['TeamId'];
            arrOrdersAux[4]['Bids'][1]['TeamId'] = this.arrAllTeams[5]['TeamId'];
            console.log(arrOrdersAux);
            this.orderService.updateOrdersTeamsByWorkShopId(arrOrdersAux, this.roomId)
              .pipe(finalize(() => this.spinner.hide())).subscribe(resp => {
                console.log("updateOrdersTeamsByWorkShopId", resp);
                this.fnAlertSuccess('The orders have been assigned');
              });
          }

      }
    }
  }
  fnSave() {
    this.spinner.show();
    console.log("fnSave", this.arrOrders, this.view);
    if(this.blnIsFacilitator){
      // if(this.view === 1)
      //   this.orderService.updateOrdersTeamsByWorkShopId(this.arrOrders, this.roomId)
      //   .pipe(finalize(()=>this.spinner.hide())).subscribe(resp => {
      //     console.log("updateOrdersTeamsByWorkShopId", resp);
      //     this.fnAlertSuccess(this.getErrorLabel('1083'));
      //   });
      // else if(this.view === 2)
      this.view = 2;
      this.titulosOfOrders = [...this.titulosMarket];
      this.arrAllTeams.forEach(team => this.titulosOfOrders.push(team['Avatar']));
      this.titulosOfOrders.push('winingTeam');
      this.blnArrow = true;
      this.fnAlertSuccess(this.getErrorLabel('1083'))
      this.fnNextView();
      this.spinner.hide();
      this.obtenerBidsConApuesta()
      // this.orderService.updateOrdersWiningTeamByWorkShopId(this._data['workShopId'], this.arrOrders)
      // .pipe(finalize(()=>this.spinner.hide())).subscribe(resp => {
      //   console.log("updateOrdersWiningTeamByWorkShopId", resp);
      //   this.fnAlertSuccess(this.getErrorLabel('1083'))
      //   this.fnNextView();
      // });
    }
  }

  fnAlertSuccess(strMessage){
    Swal.fire({
      text: '',
      imageUrl: '../../../../assets/iconSuccessfully.svg',
      imageAlt: 'icon successfully',
      confirmButtonColor: '#5EA0CC',
      html: strMessage,
    });
  }
  fnFinishMarket(){
    console.log("Llamar funcion change finish")
    this.orderService.updateOrdersWiningTeamByWorkShopId(this._data['workShopId'], this.arrOrders)
      .pipe(finalize(()=>this.spinner.hide())).subscribe(resp => {
        console.log("updateOrdersWiningTeamByWorkShopId", resp);
        this.fnAlertSuccess(this.getErrorLabel('1083'))
        // this.fnNextView();
      });
    this.orderService.updateFinishMarketByWorkshopId(this.workShopId, 1).subscribe(res =>{
      console.log(res)
      if (res.intResponse === 200) {
        this.finishMarket = 1;
        this.fnCloseDialog();

      }
    })
  }
  getFinishMarket(){
    this.orderService.getFinishMarketByWorkshopId(this.workShopId).subscribe(res =>{
      if (res.intResponse === 200) {
        this.finishMarket = res.status;
        console.log(this.finishMarket)
      }
    })
  }
  fnChangeMonthHistoric(view:number){
    // console.log(this.view);
    console.log(view);
    this.monthHistoric = view;
    this.arrOrders = this.arrAllOrders.filter(el => {
      if (el.Month == this.monthHistoric)//view board
        return el;
    });
    console.log("fnChangeMonthHistoric",this.arrOrders);
    for(var i=0; i<this.arrOrders.length;i++){
      for(var j=0;j<this.arrOrders[i].Bids.length; j++){
        if(this.arrOrders[i].WiningTeam == this.arrOrders[i].Bids[j].TeamId ){
          if((this.monthHistoric < this.month) ){
            Object.assign(this.arrOrders[i].Bids[j],{winner: true});

          }
          this.arrAllTeams.forEach(item => {
            if(item.TeamId == this.arrOrders[i].WiningTeam && item.isImpQuality){
              Object.assign(this.arrOrders[i].Bids[j],{BidQuality: Math.round(this.arrOrders[i].Bids[j].Bid*1.20)});
            }
          });
        }
      }
    }
    console.log("fnChangeMonthHistoric",this.arrOrders);

    this.arrAllTeams.forEach(team => {
      this.boardService.getHistoryMaxSupply(+this.workShopId, +this.monthHistoric, +team.TeamId).subscribe(res => {
        if (res.intResponse === 200) {
          team.historicMaxSupplies = res.jsonData.maxSupply;
          team.historySupplies = res.jsonData.maxSupply;
        } else {
          team.historicMaxSupplies = 0;
        }
      });
    });
    console.log(this.monthHistoric < this.month, this.intOpenMarket)
    if((this.monthHistoric < this.month) || this.intOpenMarket === 2){
      this.titulosOfOrders = [...this.titulosMarket];
      this.arrAllTeams.forEach(team => this.titulosOfOrders.push(team['Avatar']));
      this.titulosOfOrders.push('winingTeam');
    }else{
      this.titulosOfOrders = this.titulosBidPrepMemM2;
    }
    this.dataSourceTableOfOrders = new MatTableDataSource(
      this.arrOrders
    );
    
    this.arrAllTeams.forEach((team,index) => {
      this.fnSetAdvertisingSpend(team.TeamId,index);
      this.fnSold(team.TeamId,index);
    });
  }

  getTotalQuantities() {
    return this.arrOrders.map(t => t.Quantity).reduce((acc, value) => acc + value, 0);
  }

  fnChangeView(view){
    console.log(view);
    if(view === "Bid Prep"){
      this.view =1;
      if(this.blnIsFacilitator)
        this.titulosOfOrders = this.titulosBidPrepFac;
      else
        this.titulosOfOrders = this.titulosBidPrepMemM1;
    }else{
      console.log("AQUI")
      this.view =2;
      this.titulosOfOrders = [...this.titulosMarket];
      this.arrAllTeams.forEach(team => this.titulosOfOrders.push(team['Avatar']));
      this.titulosOfOrders.push('winingTeam');
    }
  }

  fnNextView(){
    if(this.reveal > -1){
      if(this.currentOrder.blnStatusOrder == false ){
        Swal.fire({
          text: '',
          imageUrl: '../../../../assets/iconPrevention.svg',
          imageAlt: 'icon info',
          confirmButtonColor: '#6FB45C',
          showCancelButton: false,
          showConfirmButton: true,
          html: "The facilitator must award the current order before moving to the next order.        ",
        });
        return
      }
    }
    this.ranTeam = null;
    this.view += 1;
    this.fnGetView();
    if(this.view === 3){
      this.arrAllTeams.forEach(team => team.Sold = 0);
    }
    if(this.view > 2){
      // this.arrAllTeams.forEach(team =>{
      //   if(team.TeamId == this.currentOrder.WiningTeam){
      //     this.ranTeam = team;
      //   }
      // })
      // console.log(this.ranTeam)
      // setTimeout(() => {
      //   this.ranTeam = null;
      // }, 6000);
    }
    this.fnViewSumSold();
  }

  fnPreviousView(){
    console.log(this.arrOrders.indexOf(this.currentOrder))
    if(this.arrOrders.indexOf(this.currentOrder) == 0){
      console.log("ERROR");
      Swal.fire({
        text: '',
        imageUrl: '../../../../assets/iconPrevention.svg',
        imageAlt: 'icon info',
        confirmButtonColor: '#6FB45C',
        showCancelButton: false,
        showConfirmButton: true,
        html: "The facilitator must finish the award and close the market",
      });
      return;
    }
    this.view -= 1;
    this.fnGetView();
    this.fnViewSumSold();
    if(this.view === 2){
      this.arrAllTeams.forEach((team,i) => this.fnSold(team.TeamId,i));
    }
  }

  fnViewSumSold(){
    this.arrAllTeams.forEach(team => {
      if(this.currentOrder.blnStatusOrder){
        this.fnRevealSumSold(team.TeamId,this.currentOrder.OrderNum);
      }else{
        if(!this.currentOrder.blnStatusOrder && this.view > 3){
          this.fnRevealSumSold(team.TeamId,(this.currentOrder.OrderNum-1));
        }else if(!this.currentOrder.blnStatusOrder && this.view === 3){
          team.Sold = 0;
        }
      }
    });
  }

  fnGetView(){
    let arrOrders = [];
    console.log("fnGetView",this.view);
    if(this.view === 2){
      //let jsnE : Order = {Bids:null,OrderId:null,OrderNum:null,WiningTeam:null,blnStatusOrder:null}
      let arrO = this.arrOrders//.concat([jsnE]);
      this.dataSourceTableOfOrders = new MatTableDataSource(arrO);
    }
    else
      for (const [index, order] of this.arrOrders.entries()) {
        if(order.OrderNum === (this.view + ((this.month*100)-2) )){
          this.currentOrder = order;
          console.log(this.bidsAsignadas,this.bidsConApuesta)
          console.log("this.currentOrder",this.currentOrder, index);
          if(this.arrOrders.length < 5){
            arrOrders = this.arrOrders;
          }else{
            if(index <= 5){
              arrOrders = this.arrOrders.slice((0),(6));
            }else{
              arrOrders = this.arrOrders.slice((index-5),(index+1));
            }
          }
          this.reveal++;
          console.log("arrOrders",arrOrders,this.reveal);
          this.dataSourceTableOfOrders = new MatTableDataSource(
            arrOrders
          );
          break;
        }
      }
  }

  fnGetTeamVersus(teamId){
    let strAvatar = "";
    for (const team of this.arrAllTeamsAux) {
      if(team['TeamId'] === teamId){
        strAvatar = team['Avatar']
        break;
      }
    }
    return strAvatar;
  }

  fnNextStep(currentOrder:Order,index){
    console.log(currentOrder)
    let bid = currentOrder.Bids[index];
    if(bid.TeamId == 0 && bid.intStatusTeam == 0){
      console.log("Comprobar revelacion de bids")
      let apuestas = currentOrder.Bids.filter(x => x.Bid > 0 && x.TeamId != 0)
      let apuestasReveladas = currentOrder.Bids.filter(x => x.intStatusTeam == 1)
      console.log(apuestas, apuestasReveladas)
      console.log(apuestas.length, apuestasReveladas.length)
      if(apuestasReveladas < apuestas){
        console.log("Revela todas las bid");
        Swal.fire({
          text: '',
          imageUrl: '../../../../assets/iconPrevention.svg',
          imageAlt: 'icon info',
          confirmButtonColor: '#6FB45C',
          showCancelButton: false,
          showConfirmButton: true,
          html: "The facilitator must reveal all bids of the current order first",
        });
        return
      }
    }
    if(bid.TeamId == currentOrder.WiningTeam && bid.intStatusTeam == 1){
      console.log("Comprobar revelacion de bids")
      let apuestas = currentOrder.Bids.filter(x => x.Bid > 0 && x.TeamId != 0)
      let apuestasReveladas = currentOrder.Bids.filter(x => x.intStatusTeam == 1 && x.Bid > 0 && x.TeamId != 0)
      console.log(apuestas, apuestasReveladas)
      console.log(apuestas.length, apuestasReveladas.length)
      if(apuestasReveladas < apuestas){
        console.log("Revela todas las bid");
        Swal.fire({
          text: '',
          imageUrl: '../../../../assets/iconPrevention.svg',
          imageAlt: 'icon info',
          confirmButtonColor: '#6FB45C',
          showCancelButton: false,
          showConfirmButton: true,
          html: "The facilitator must reveal all bids of the current order first",
        });
        return
      }
    }
    if(bid.intStatusTeam == 1 && bid.TeamId != currentOrder.WiningTeam && currentOrder.blnStatusOrder != true){
      console.log("Revela todas las bid");
  Swal.fire({
    text: '',
    imageUrl: '../../../../assets/iconPrevention.svg',
    imageAlt: 'icon info',
    confirmButtonColor: '#6FB45C',
    showCancelButton: false,
    showConfirmButton: true,
    html: "The facilitator must reveal all bids of the current order first",
  });
  return
    }
    if(bid.blnNotSupplies && bid.intStatusTeam == 0){
      bid.intStatusTeam = 1;
      return;
    }
    if(bid.blnNotSupplies && bid.intStatusTeam == 1)
      return;
    if((index === (currentOrder.Bids.length - 1)) && this.teamId === currentOrder.WiningTeam){
      bid.intStatusTeam = 2;
      currentOrder.blnStatusOrder = true;
    }
    else{
      bid.intStatusTeam += (bid.intStatusTeam === 1 && bid.TeamId !== currentOrder.WiningTeam) ? 0 : 1;
      console.log(bid.intStatusTeam)
      if(bid.intStatusTeam >=2 ){
        this.arrAllTeams.forEach(team =>{
          if(team.TeamId == this.currentOrder.WiningTeam){
            this.ranTeam = team;
          }
        })
        console.log(this.ranTeam)
        this.bidsAsignadas++;
        setTimeout(() => {
          this.ranTeam = null;
        }, 6000);
        currentOrder.blnStatusOrder = true;
        console.log("aqui")
        this.fnRevealSumSold(bid.TeamId,currentOrder.OrderNum);
      }else{
        if(bid.intStatusTeam == 1 && bid['isWithdrawn'] && !bid.blnNotSupplies){
          bid['showExceedBid'] = true;
          console.log("AQUI")
          setTimeout(() => {
            bid['showExceedBid'] = false;
          }, 1000);
        }
      }
    }
  }
  fnGetRemaining(teamid){
    let jsnTeam = this.arrAllTeams.filter(x => x.TeamId == teamid)
    return (this.monthHistoric === this.month ? jsnTeam[0].Supplies : jsnTeam[0].historySupplies) - jsnTeam[0].Sold
  }
  fnTimer(){
    console.log(this.statusBoard);
    if(this.month == 1 && (this.boardMode == "NM" || this.boardMode == "SSNM")){
      this.canTimer =  this.arrAllTeams.every(team => team['Simpleb_step'] >= 6);
      console.log(this.canTimer)
      if(!this.canTimer){
        Swal.fire({
          text: '',
          imageUrl: '../../../../assets/iconPrevention.svg',
          imageAlt: 'icon info',
          confirmButtonColor: '#6FB45C',
          showCancelButton: false,
          showConfirmButton: true,
          html: "The facilitator won't be able to ‘start the countdown’ for month 1 Market until all teams have completed Step 6 of Simple Balance Sheet.",
        });
        return
      }
      if(this.month == 1 && (this.boardMode == "NM" || this.boardMode == "SSNM") && (this.statusBoard == 0 || this.statusBoard == null)){
        Swal.fire({
          text: '',
          imageUrl: '../../../../assets/iconPrevention.svg',
          imageAlt: 'icon info',
          confirmButtonColor: '#6FB45C',
          showCancelButton: false,
          showConfirmButton: true,
          html: "The facilitator should move to the market as well. ",
        });
        return

      }
      // console.log(s)
    }
    this.minutes = this.month == 1 ? 5 : 10;
    this.dialogRefTimer = this.dialog.open(this.timer, {
      width: '350px',
      height: '250px',
      data: {
        name: 1
      }
    });
    console.log(this.arrAllTeams, this.arrOrders);

    this.dialogRefTimer.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.arrOrders, this.arrAllOrders)

      if(result>0){
        this.workshopService.setLogFac(this._data.teamId,this._data.userName,'Market', 'Open Market', this._data.month).subscribe(res =>{ }) //Log for fac
        this.orderService.updateFinishMarketByWorkshopId(this.workShopId, 0).subscribe(res => {
          if (res.intResponse === 200) {
            this.finishMarket = 0;
          }
        });
        this.arrOrders.forEach(order => {
          order.WiningTeam = 0;
          order.Bids.forEach(bid => {
            bid.blnNotSupplies = false;
            bid['isWithdrawn'] = false;
          });
         });
        // this.arrAllOrders.forEach(element => {
        //   element.WiningTeam = 0;
        // });
        // this.arrOrders.forEach(element => {
        //   element.WiningTeam = 0;
        // });
        // this.orderService.updateOrdersWiningTeamByWorkShopId(this._data['workShopId'], this.arrOrders)
        // .pipe(finalize(()=>this.spinner.hide())).subscribe(resp => {
        //   console.log("updateOrdersWiningTeamByWorkShopId", resp);
        //   this.fnAlertSuccess(this.getErrorLabel('1083'))
        //   // this.fnNextView();
        // });
        this.boardService.updateMarketStep(this.workShopId, 1, this.roomId).subscribe(res => {
          if (res.intResponse === 200 && res.jsonData) {
            this.marketStep = res.jsonData.MarketStep;
            if(this.marketStep == 1){
              this.blnAvalibleBid = true;
            }
          }
        });
        this.socketOrder.timer(this.roomId,result);
        this.fnGetTime(result);
      }
    });
  }

  fnCloseTimer(){
    this.dialogRefTimer.close(0);
    //this.dialogRefTimer.close()
  }

  fnGoTimer(){
    console.log("fnGoTimer", this.minutes, this.seconds);
    let seconds = (this.minutes * 60) + this.seconds;
    seconds = seconds > 0 ? seconds : 0;
    this.dialogRefTimer.close(seconds);
    //this.dialogRefTimer.close()
  }

  fnSetMinutes(event){
    console.log("fnSetMinutes",event);
  }

  fnSetSeconds(event){
    console.log("fnSetSeconds",event);
  }

  fnGetTime(counter:number){
    console.log("ENTRA A fnGetTime", counter);
    let index = 0;
    clearInterval(this.orderService.intervalId);
    var mins = ~~(counter / 60);
    var secs = ~~(counter % 60);
    this.time = (mins<10?"0"+mins:mins) + ":" +  (secs<10?"0"+secs:secs);
    this.orderService.intervalId = setInterval(() => {
      //console.log("counter",counter);
      counter = counter - 1;
      var mins = ~~(counter / 60);
      var secs = ~~(counter % 60);
      this.time = (mins<10?"0"+mins:mins) + ":" +  (secs<10?"0"+secs:secs);
      if(this.blnIsFacilitator){
        index++;
        if(index === 5){
          this.socketOrder.timer(this.roomId,counter);
          index = 0;
        }
      }
      if(counter <= 0) clearInterval(this.orderService.intervalId);
    }, 1000)
  }

  async fnCheckTeams(){
    if (this.month >= 2) {
      try {
          const res = await this.boardService.getTeamsStatusByWorkshopId(this.workShopId).toPromise();
          
          if (res) {
              console.log('la llamada dice', res);
              let anyTeamNotReady = res.jsonData.some(team => team.Status < 10);
              console.log(anyTeamNotReady);
              if (anyTeamNotReady) {
                  Swal.fire({
                      text: '',
                      imageUrl: '../../../../assets/iconPrevention.svg',
                      imageAlt: 'icon info',
                      confirmButtonColor: '#6FB45C',
                      showCancelButton: false,
                      showConfirmButton: true,
                      html: "All teams must be ready to bet to be able to stop the bid prep.",
                  });
                  return; 
              } else {
                this.fnOpenMarket();
              }
          }
      } catch (error) {
          console.error('Error fetching team status:', error);
      }
    } else {
      this.fnOpenMarket();
    }
  }

  async fnOpenMarket(){
    console.log(this.arrOrders,this.arrAllTeams);    
    if(this.month == 1 && (this.boardMode == "NM" || this.boardMode == "SSNM") && this.arrAllTeams.length>=4){
      let counter = 0;
      this.arrOrders.forEach(order =>{
        order.Bids.forEach(bid =>{
          if(bid.Bid == 40){
            counter++;
          }
        })

      })
      console.log(counter);
      console.log(this.arrOrders.length*2);
      if(counter != (this.arrOrders.length*2)){
        Swal.fire({
          text: '',
          imageUrl: '../../../../assets/iconPrevention.svg',
          imageAlt: 'icon info',
          confirmButtonColor: '#6FB45C',
          showCancelButton: false,
          showConfirmButton: true,
          html: "All teams must have bids prepared before the facilitator can stop the countdown and run the market.",
        });
        return
      }
    }
    this.boardService.updateMarketStep(this.workShopId, 2, this.roomId).subscribe(res => {
      if (res.intResponse === 200 && res.jsonData) {
        this.marketStep = res.jsonData.MarketStep;
      }
    });
    this.workshopService.setLogFac(this._data.teamId,this._data.userName,'Market', 'Close Bids', this._data.month).subscribe(res =>{ }) //Log for fac
    this.socketOrder.openMarket(this.workShopId,this.roomId,1);
    this.fnDisableBets();
    this.fnAlertSuccess(this.getErrorLabel('1084'));
    this.intOpenMarket = 1;
    this.arrAllTeams.forEach(team => team.Sold = 0);
    this.arrOrders.forEach(order => {
      //order.WiningTeam = 0
      order.Bids.forEach(bid=> {bid.blnNotSupplies = false
      });

    });
    if(this.month > 1 || this.boardMode == "DM"){
      for (const jsonOrder of this.arrOrders) {
        let response = await this.fnSetWiningTeam(jsonOrder.OrderId);
        console.log("WINNEEEEERS tmp",response);
        if(response === true) {
          this.blnEmpate = true;
          break;
        };
      }
      console.log("ORDERS final",this.arrOrders);
      this.arrOrders = this.arrAllOrders.filter(el => {
        if (el.Month == this.monthHistoric)//view board
          return el;
      });
      for(var i=0; i<this.arrOrders.length;i++){
        for(var j=0;j<this.arrOrders[i].Bids.length; j++){
          if(this.arrOrders[i].WiningTeam == this.arrOrders[i].Bids[j].TeamId ){
            this.arrAllTeams.forEach(item => {
              if(item.TeamId == this.arrOrders[i].WiningTeam && item.isImpQuality){
                Object.assign(this.arrOrders[i].Bids[j],{BidQuality: Math.round(this.arrOrders[i].Bids[j].Bid*1.20)});
              }
            });
          }
        }
      } 
    }else{
      this.fnSetWiningTeamMonth1();
    }
  }
  fnCloseMarket(){
    console.log(this.bidsAsignadas, this.bidsConApuesta)
    if(this.bidsAsignadas < this.bidsConApuesta){
      Swal.fire({
        text: '',
        imageUrl: '../../../../assets/iconPrevention.svg',
        imageAlt: 'icon info',
        confirmButtonColor: '#6FB45C',
        showCancelButton: false,
        showConfirmButton: true,
        html: "Facilitator must award all bids first",
      });
      return
    }
    console.log(this.currentOrder)

    if(this.month > 1 && this.currentOrder.blnStatusOrder == false){
      Swal.fire({
        text: '',
        imageUrl: '../../../../assets/iconPrevention.svg',
        imageAlt: 'icon info',
        confirmButtonColor: '#6FB45C',
        showCancelButton: false,
        showConfirmButton: true,
        html: "Facilitator must award all bids first",
      });
      return
    }
    
    this.boardService.updateMarketStep(this.workShopId, 0, this.roomId).subscribe(res => {
      if (res.intResponse === 200 && res.jsonData) {
        this.marketStep = res.jsonData.MarketStep;
      }
    });
    this.orderService.updateFinishMarketByWorkshopId(this.workShopId, 0).subscribe(res => {
      if (res.intResponse === 200) {
        this.finishMarket = 0;
      }
    });
    this.socketOrder.openMarket(this.workShopId,this.roomId,2);
    this.fnAlertSuccess(this.getErrorLabel('1085'));
    this.intOpenMarket = 2;
    this.workshopService.setLogFac(this._data.teamId,this._data.userName,'Market', 'Close Market', this._data.month).subscribe(res =>{ }) //Log for fac
    this.fnCloseDialog();
  }

  fnDisableBets(){ 
    clearInterval(this.orderService.intervalId);
    this.orderService.intervalId = null;
    this.time = "00:00";
    this.blnAvalibleBid = false;
  }
  // fnHideWiningTeam2(arrBids: Array<Bid>, teamId, quantity: number){
  //   let blnHide = true;
  //   console.log(arrBids,teamId,quantity)
  //   arrBids.forEach(bid => {
  //     if(teamId === this.teamId || (bid.TeamId === teamId && bid.Bid > 0))
  //       blnHide = false;
  //     if((bid.TeamId === teamId && bid.blnNotSupplies == true)){
  //       blnHide = true;
  //     }
  //     if((bid.TeamId === teamId && bid['isWithdrawn'] == true)){
  //       blnHide = true;
  //     }
  //   });
  //   return blnHide;
  // }
  fnHideWiningTeam(arrBids: Array<Bid>, teamId, quantity: number){
    let blnHide = true;
    arrBids.forEach(bid => {
      if(teamId === this.teamId || (bid.TeamId === teamId && bid.Bid > 0))
        blnHide = false;
      if((bid.TeamId === teamId && bid.blnNotSupplies == true)){
        blnHide = true;
      }
      if((bid.TeamId === teamId && bid['isWithdrawn'] == true)){
        blnHide = true;
      }
    });
    return blnHide;
  }

  fnGetColorByTeamId(teamId){
    let color = "#blueviolet";
    for (const team of this.arrAllTeamsAux) {
      if(team.TeamId === teamId){
        color = team.Color;
        break;
      }
    }
    return color;
  }

  fnGetTextColorByTeamId(teamId){
    let color = "white";
    for (const team of this.arrAllTeamsAux) {
      if(team.TeamId === teamId){
        color = team.Color === '#50b1a8' || team.Color === '#ffe998' || team.Color === '#84eeeb' || team.Color === '#bec3ff' || team.Color === '#e4e4ec' || team.Color === '#7faeff' ? 'black' : 'white';
        break;
      }
    }
    return color;
  }

  fnValidTime(){
    let blnDisable = false;
    if(this.month === 1){
      if(this.minutes > 5 || (this.minutes === 5 && this.seconds > 0))
        blnDisable = true;
    }else if(this.month > 1){
      if(this.minutes > 15 || this.minutes < 10 || (this.minutes === 15 && this.seconds > 0))
        blnDisable = true;
    }
    return blnDisable;
  }

  fnCloseDialog(){
    this.workshopService.setLogFac(this._data.teamId,this._data.userName,'Market', 'Close', this._data.month).subscribe(res =>{ }) //Log for fac

    console.log(this.arrOrders);
    // if(this.hasControl){
    //   this.arrOrders.forEach(order =>{
    //     order.Bids.forEach(bid =>{
    //       if(bid.TeamId == this.teamId){
    //         console.log(bid);
    //         // this.fnChangeBid(bid.BidId,bid,"row")
    //         this.socketOrder.updateBid(this.roomId, order.OrderId, bid.Bid, bid.BidId);
    //       }
    //     })
    //   })
    // }
    
    this.matDialogRef.close();
  }

  fnGetImprovementOptionsByTeamId(teamId){
    this.improvementService.getImprovementOptionsByTeamId(teamId).subscribe(response=>{
      console.log("getImprovementOptionsByTeamId",response);
      response['options'].forEach(option => {
        if(option['Option'] === 3 && option['Status'] === 3)
          this.cutomizingOption = true;
      });
    })
  }

  fnGetOpenMarket(workshopId){
    return new Promise(resolve =>{
      this.orderService.getOpenMarketByWorkShopId(workshopId).subscribe(response => {
        console.log(response, 'isOpenMarket');
        if (response.intResponse == 200) {
          this.intOpenMarket = response.openMarket as number;
        }
        resolve('');
      });
    })
  }

  fnGetImprovementsByWorlkshopId(workshopId){
    this.improvementService.getImprovementsByWorkShopId(workshopId).subscribe(response=>{
      console.log("fnGetImprovementsByWorlkshopId",response);
      this.improvements = response['improvements'];
    });
  }

  fnGetQualityOption(teamId): Promise<boolean>{
    return new Promise<boolean>(resolve=>{
      let qualityOption = false;
      for (const improvement of this.improvements) {
        for (const option of improvement.options) {
          if(option.Option === 4 && option.Status === 3 && improvement.TeamId === teamId)
            qualityOption = true;
        }  
      }
      resolve(qualityOption);
    })
  }

  fnGetQualityOption2(teamId){

      let qualityOption = false;
      for (const improvement of this.improvements) {
        for (const option of improvement.options) {
          if(option.Option === 4 && option.Status === 3 && improvement.TeamId === teamId)
            qualityOption = true;
        }  
      }
      return qualityOption;
  }


  fnClickCoin(order:Order) {
    let arrWiners:Array<number> = order.Winers;
    console.log("RAAAANNN fnClickCoin", Math.floor(Math.random() * arrWiners.length));
    let winerId = arrWiners[Math.floor(Math.random() * arrWiners.length)];
    /*var flipResult = Math.random();
    if (flipResult < 0.5) {
      this.strClassCoin = "flipHead";
    } else {
      this.strClassCoin = "flipTail";
    }*/
    this.strClassRoulette = "rotate-roulette";
    setTimeout(()=>{
      this.strWinnerRoulette = this.fnGetTeamVersus(winerId);
      setTimeout(async ()=>{
        order.WiningTeam = winerId;
        order.Winers = [];
        let teamIndex = this.arrAllTeams.findIndex(team=> team.TeamId === winerId);
        //console.log("fnSold",winnerTeam);
        const response2 = await this.fnSold(winerId, teamIndex);
        console.log("COIN",response2);
        this.strWinnerRoulette = "";
        this.strClassRoulette = "";
        this.spinner.show();
        const responseOrdersWining = await this.fnOrdersWiningTeam();
        this.spinner.hide();
        this.blnEmpate = false;
      },2000);
    },3000)
  }

  fnOrdersWiningTeam(){
    return new Promise(async resolve=>{
      for (const order of this.arrOrders) {
        console.log("order.WiningTeam",order.WiningTeam);
        if(order.WiningTeam === undefined || order.WiningTeam === null || order.WiningTeam === 0){
          let responseWiner = await this.fnSetWiningTeam(order.OrderId);
          if(responseWiner === true) break;
        }
      }
      resolve(true);
    })
  }

  fnAddSlogan(team:Team){
    console.log("fnAddSlogan",team);
    this.socketOrder.setSlogan(this.roomId, team.TeamId,team.Slogan);
  }

  fnSetTeamSlogan(teamId, slogan){
    for (const team of this.arrAllTeams) {
      if(team.TeamId === teamId){
        team.Slogan = slogan;
      }
    }
    console.log("fnSetTeamSlogan", this.arrAllTeams);
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

  getErrorLabel(labelNumber: string, code: string | null = null): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    let msg = ''
    if (tmp) {
      const LabelBase = tmp.LabelBase;
      let errorCode = code;
      if (!code) {
        const array = LabelBase.split(':');
        errorCode = array.length > 0 ? array[0] : '';
      }
      const className = environment.isProd ? 'lightgray' : 'gray';
      msg = `<div><span style="color:${className}; font-size: 12px">${errorCode}: </span>${tmp.Label}</div>`;
    }
    return msg;
  }
  validateInputNumber(event: KeyboardEvent){
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {    
      event.preventDefault();
  }
  }
  validateInputNumberForMonth(event: KeyboardEvent, bid:Event) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    const input2 = event.target as HTMLInputElement
    const currentValue2 = input2.value;
    console.log(currentValue2,event);
    if (currentValue2 == "40" && event.key == "Enter") {
      return
    }
    if(this.month == 1 && (this.boardMode == "NM" || this.boardMode == "SSNM")){
      if (inputChar !== '4' && inputChar !== '0') {
        event.preventDefault();
        console.log("aqui")
        Swal.fire({
          text: '',
          imageUrl: '../../../../assets/iconPrevention.svg',
          imageAlt: 'icon info',
          confirmButtonColor: '#6FB45C',
          showCancelButton: false,
          showConfirmButton: true,
          html: this.getErrorLabel('2000'),
        });
      }
    }else{
      if (!pattern.test(inputChar)) {    
          event.preventDefault();
      }
    }
    if(this.month == 1 && (this.boardMode == "NM" || this.boardMode == "SSNM")){
      const maxLength = 2; // Establece el número máximo de dígitos permitidos
      const input = event.target as HTMLInputElement
      const currentValue = input.value;
      console.log(currentValue,event);
      if (currentValue.length >= maxLength && event.key != "Enter") {
        if(currentValue[0] == "0" ){
          bid['Bid'] = currentValue.slice(0, maxLength);
        
        }else{
          bid['Bid'] = "";

        }
        //input.value = currentValue.slice(0, maxLength);
        console.log("SE PASA")
      }else if(currentValue.length == maxLength-1 && event.key != "Enter"){
        console.log("IGUALLL")
        if(currentValue[0] == "0" ){
          bid['Bid'] = currentValue[1]

        }
        if(currentValue[1] == "4" ){
          bid['Bid'] = currentValue[1]

        }
      }
    }

  }

  get isTimerNull(): boolean{
    return this.orderService.intervalId === null;
  }

  get isNotAllStepsCompleted() {
    const latestCheckBoxCompletedId = this._data.latestCheckBoxCompletedId;
    return (this.month === 1 && this.statusBoard < 29) || (this.month > 1 && latestCheckBoxCompletedId < 10);
  }
  click(bid){
    console.log(bid,this.currentOrder,this.arrAllOrders,this.arrOrders)
  }
  fnColor(color:string){
    switch (color) {
      case '#ffe998':
         return '#E68D32'
        break;
      case '#bec3ff':
        return '#8F8EE5'
        break;
      case '#84eeeb':
        return '#4DA4B7'
        break;
      case '#50b1a8':
        return '#41B397'
        break;
      case '#7faeff':
        return '#5A8DFF'
        break;
      default:
        return color
    }
  }
  fnCheckTie(row:Order , teamId){
  // console.log(row, teamId)
    if(row.Winers.includes(teamId)){
      return true;
    }else{
      return false;
    }
  }
  obtenerBidsConApuesta(){
    console.log(this.teamId, this.arrOrders)
    let aux = this.arrOrders.filter(x => this.teamId != x.WiningTeam)
    this.bidsConApuesta = aux.length
    console.log(aux,this.bidsConApuesta);
  }
  prueba(team,row){
    console.log(team,row)
  }
}
