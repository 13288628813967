import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeamService } from 'src/app/services/team/team.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BalanceIncomeService } from 'src/app/services/sockets/balance-income/balance-income.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-record-balance-sheet',
  templateUrl: './record-balance-sheet.component.html',
  styleUrls: ['./record-balance-sheet.component.scss']
})
export class RecordBalanceSheetComponent implements OnInit {
  arrTextLabels = []
  arrAllTeams = [];
  steps : number[] = [1, 2, 3, 4, 5, 6];
  destroy$: Subject<boolean> = new Subject<boolean>();
  workshopID = 0;
  constructor(
    private teamService: TeamService,
    private activatedRoute: ActivatedRoute,
    public matDialogRef: MatDialogRef<RecordBalanceSheetComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private spinner: NgxSpinnerService,
    private balanceIncomeService: BalanceIncomeService,
    private languageservice: LanguageService
    ) {
      //console.log("this._data", this._data);
     }

  async ngOnInit(): Promise<void> {
    let isFac = localStorage.getItem('IsFacilitator')
    console.log(isFac);
    console.log(this._data);
        this.workshopID = this._data.workshopId;
        this.arrTextLabels = (await this.languageservice.getValidationLabels(parseInt(this.workshopID.toString())).toPromise()).textLabels;
    
      switch (this._data['businessModel']) {
        case "Mfg EBIT":
          this.steps = [1, 2, 3, 4, 5, 6];
          break;
        case "S&S EBITDA":
          this.steps = [1, 2, 3];
          break;
        case "S&S EBITDA-B":
            this.steps = [1, 2, 3, 4];
            break;
        
        default:
          this.steps = [1, 2, 3, 4, 5, 6]
          break;
      }
      console.log(environment);
      this.fnGetAllTeamsAndMembers();
      this.initSocket();
   
  }
  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }


  fnGetAllTeamsAndMembers(){
      this.spinner.show();
      this.teamService.getAllTeamsAndMembersById(this.workshopID).subscribe(resp=>{
      console.log("fnGetAllTeamsAndMembers",resp);
      this.arrAllTeams = resp['teams'];
      /*this.matDialogRef.updateSize('550px', this.arrAllTeams.length === 6 ? '325px' : this.arrAllTeams.length === 5 ? '305px' : '285px');*/
      this.spinner.hide();
    })
  
  }

  fnResultado(step:number, control){
    let label = "";
    const doneLabel = 'DONE';
    switch(control){
      case 1:
        if(step >= control){
          label = doneLabel;
        }
        else{
          label = "";
        }
        break;
      case 2:
        if(step >= control){
          label = doneLabel;
        }
        else{
          label = "";
        }
        break;
      case 3:
        if(step >= control){
          label = doneLabel;
        }
        else{
          label = "";
        }
        break;
      case 4:
        if(step >= control){
          label = doneLabel;
        }
        else{
          label = "";
        }
        break;
      case 5:
        if(step >= control){
          label = doneLabel;
        }
        else{
          label = "";
        }
        break;
    }
    return label;
  }

  fnResultado2(step:number, control){
    let blnicon = false;
    const doneLabel = true;
    switch(control){
      case 1:
        if(step >= control){
          blnicon = doneLabel;
        }
        else{
          blnicon = false;
        }
        break;
      case 2:
        if(step >= control){
          blnicon = doneLabel;
        }
        else{
          blnicon = false;
        }
        break;
      case 3:
        if(step >= control){
          blnicon = doneLabel;
        }
        else{
          blnicon = false;
        }
        break;
      case 4:
        if(step >= control){
          blnicon = doneLabel;
        }
        else{
          blnicon = false;
        }
        break;
      case 5:
        if(step >= control){
          blnicon = doneLabel;
        }
        else{
          blnicon = false;
        }
        break;
      case 6:
        if(step >= control){
          blnicon = doneLabel;
        }
        else{
          blnicon = false;
        }
        break;
    }
    return blnicon;
  }


  initSocket(){
    this.balanceIncomeService.setupSocketConnection();
    this.balanceIncomeService._balance
    .pipe(takeUntil(this.destroy$)).subscribe(response => {
      if(response){
        switch (response.status) {
          case 'sbsStepCompleted':
            const { teamId, step } = response.data;
            const row = this.arrAllTeams.find(row => row.team.TeamId === teamId);
            if(row){
              row.team.Simpleb_step = step;
            }
            break;
          default:
            break;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.balanceIncomeService.closeListener();
  }
  close(){
    this.matDialogRef.close()
    // window.close()
  }
}
