import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { SocketImprovementService } from 'src/app/services/sockets/socket-improvement/socket-improvement.service';
import Swal from 'sweetalert2';
import { startMonth } from 'src/app/views/helpers/status-month';
import { ImprovementService } from 'src/app/services/improvement/improvement.service';
import { OrderService } from 'src/app/services/order/order.service';
import { environment } from 'src/environments/environment';
import { TeamService } from 'src/app/services/team/team.service';
import { BoardService } from 'src/app/services/board/board.service';
import { SocketioService } from 'src/app/services/socketio.service';
import { WorkshopService } from 'src/app/services/workshop/workshop.service';

@Component({
  selector: 'app-dialog-month-status',
  templateUrl: './dialog-month-status.component.html',
  styleUrls: ['./dialog-month-status.component.scss']
})
export class DialogMonthStatusComponent implements OnInit {
  selectedOption = "1"
  teams:any [] = []
  month = 0;
  showBtnImpOpt = true;
  roomWorkShopId = "";
  workshopId = 0;
  arrStartMonth=startMonth;
  arrIterator;
  previousMonth = 0;
  isPendingProject = 0;
  improvementStatus = -1;
  cycles: number = 0
  disableStartMonth: boolean = false
  arrTextLabels = []
  WStype: string;
  constructor(
    public matDialogRef: MatDialogRef<DialogMonthStatusComponent>,
    private spinner: NgxSpinnerService,
    private socketImprovement: SocketImprovementService,
    private orderService: OrderService,
    private improvementService: ImprovementService,
    private teamsService: TeamService,
    private boardService: BoardService,
    private socketService: SocketioService,
    private workshopService: WorkshopService,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) { 
    console.log(this._data)
    this.previousMonth = this._data['previousMonth'];
    this.workshopId = this._data['workshopId'];
    this.roomWorkShopId = "roomWorkShop" + this.workshopId;
    this.month = this._data['month'];
    this.isPendingProject = _data['isPendingProject'];
    this.arrTextLabels = this._data['arrTextLabels'];
    this.showBtnImpOpt = this._data['showBtnImpOpt'];
    this.WStype = this._data['WStype']
    this.cycles = this._data['cycles']
    // this.showBtnImpOpt = (this.month == 3 || this.month == 4) && 
    // (this._data['cycles'] == 4 || this._data['cycles'] == 5) || 
    // (this.month == 3 || this.month == 5) && this._data['cycles'] == 6;
    this.arrIterator = new Array(this.showBtnImpOpt ? 3 : this.month == 1 ? 1 : 2);
    console.log(this.showBtnImpOpt,this.arrTextLabels);
    if(!this.showBtnImpOpt){
      this.arrStartMonth.forEach(item => {
        item.td3 = item.td4;
      });
      if(this.month == 1){
        this.arrStartMonth.forEach(item => {
          item.td2 = item.td4;
        })
      }
    }

  }

  ngOnInit(): void {
    this.workshopService.setLogFac(this._data['teamId'],this._data.userName,'Month By Month', 'Open', this._data.month).subscribe(res =>{ }) //Log for fac
    this.fnGetImprovementStatus(this.workshopId);
    // this.fnGetProjectStatus(this.workshopId); // Quieren que por default este en 1 siempre
    this.fnGetAllTeams(this.workshopId)
  }

  fnGetImprovementStatus(workShopId){
    this.improvementService.getImprovementStatus(workShopId).subscribe(response=>{
      console.log("fnGetImprovementStatus",response);
      this.improvementStatus = response['improveStatus'];
    })
  }
  fnGetProjectStatus(workShopId){
    this.improvementService.getProjectStatus(workShopId).subscribe(response=>{
      console.log("fnGetProjectStatus",response);
      this.selectedOption = response['Project'].toString();
      console.log("fnGetProjectStatus",this.selectedOption);
      
    })
  }
  fnGetAllTeams(ws){
    this.teamsService.getAllTeamsById(ws).subscribe(response => {
      console.log(response,this.month)
      this.teams = response.teams;
      let aux:any = this.teams.filter(x => x.Month < this.month)
      let aux2:any = this.teams.filter(x => x.Month == 2)
      if(this.month == 1 && aux2.length > 0){
        this.disableStartMonth = true

        Swal.fire({
          showCancelButton: false,
          showConfirmButton:true,
          title: 'Use magic buttons to advance your board, before starting new month',
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
          allowOutsideClick: false,
          icon: 'warning'
        })
      }
      if (this.teams.length <= 1){
        console.log("Not enougth teams to start the game")
        this.disableStartMonth = true
        Swal.fire({
          showCancelButton: false,
          showConfirmButton:true,
          title: 'There should be at least two teams to start the game',
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
          allowOutsideClick: false,
          icon: 'warning'
        })
      }
      if (aux.length>0){
        this.disableStartMonth = true
        let auxEquipos:string = "" 
        aux.forEach(element => {
          auxEquipos += " - " +element.Avatar
        }); 
        console.log(auxEquipos)
        Swal.fire({
          html: "All teams should complete the previous month to start a new one. <p>Incomplete Teams: <strong>"+auxEquipos+"</strong></p>",
          showCancelButton: false,
          showConfirmButton:true,
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
          allowOutsideClick: false,
          icon: 'warning'
        })
      }
      console.log(aux,this.disableStartMonth)
      console.log(this.month,this.disableStartMonth)
      if(this.month > 3){
        if(this.month < this.teams[0].Month){
          console.log("Caso facilitador atras")
          if(this.teams[0].improvements.length > 1){
            if(this.teams[0].improvements[0].Option == 6 || this.teams[0].improvements[1].Option == 6){
              console.log("Caso mes sin mejoras")
              this.selectedOption = "0"
            }
          }else{
            if(this.teams[0].improvements[0].Option == 6){
              console.log("Caso mes sin mejoras")
              this.selectedOption = "0"
    
            }
          }
        }
      }
      

    })
  }

  fnSelectOption(option){
    this.improvementStatus = option;
    if(option == 1){
      this.fnAlertSuccess(this.getErrorLabel('141', '1099'));
    }else if(option == 2){
      this.workshopService.setLogFac(this._data.teamId,this._data.userName,'Month By Month', 'Start month', this._data.month).subscribe(res =>{ }) //Log for fac
      this.previousMonth = this.month;
      this.fnAlertSuccess(this.getErrorLabel('131-5013'));
      this.improvementService.setAllTeamsUnApprovedImprovement(this.workshopId).subscribe(response => {
        console.log(response, 'setAllTeamsUnApprovedImprovement');
        if(response.intResponse == 200){
        }
      });
      this.fnUpdateOpenMarketByWorkshopId(this.workshopId, 0);
    }else if(option == 0){
      this.fnAlertSuccess(this.getErrorLabel('145-5013'));
      console.log("Es aqui");
    }
    this.fnStatusMonthImprove(option);
  }

  async fnStatusMonthImprove(status){
    if(this.month == 1){
      let label = this.arrTextLabels.filter(x => x.LabelNumber == "2012");
      console.log(label);
      if(label){
        this.teams.forEach((team, index) =>{
          team['Avatar'] = `${label[0]['Label']} ${index+1}`
        })
      }
      console.log(this.teams);
      this.socketImprovement.updateAvatar(
        this.workshopId,
        this.roomWorkShopId,
        this.teams
      );
    }
    this.socketImprovement.updateImproveStatus(
      this.workshopId,
      this.roomWorkShopId,
      status,
      this.month
    );
    if(status == 0 && this.selectedOption == "0"){ //BUG: Projects when starting a month: Si el mes anterior no se implemento proyecto, dejamos la misma opcion por default
      this.improvementStatus = 1
      this.socketImprovement.updateImproveStatus(
        this.workshopId,
        this.roomWorkShopId,
        this.improvementStatus,
        this.month
      );
    }
    console.log("Entra a funcion this.setPendingMonth")
    const x = await this.setPendingMonth(status);
    if(x){
    //caso especial proyectos en otro mes...
    console.log("continuamos con status impru")
      if(status == 2 && this.isPendingProject != 0 && this.showBtnImpOpt && this.selectedOption == "1"){
        console.log("CASO ESPECIAL")
        this.improvementService.updateProjectsStatus(this.workshopId, 1)
        .subscribe(response => {
          console.log("updateProjectsStatus", response);
          this.executeCodeCaseProjects()
        })
      }else{
        if (status == 2 && this.selectedOption == "0" && this.showBtnImpOpt) { //BUG: Projects when starting a month: Caso no proyectos y mandar socket a 0
          console.log('Opción seleccionada:', this.selectedOption);
          this.improvementService.updateProjectsStatus(this.workshopId, this.selectedOption)
            .subscribe(response => {
              console.log(this.teams);
              this.executeCode()
            })
        } else if ((status == 2 && this.selectedOption == "1" && this.showBtnImpOpt) || (status == 2 && !this.showBtnImpOpt)) { //BUG: Projects when starting a month: Caso con proyectos y mandar socket a 1 
          console.log('Opción seleccionada:', this.selectedOption);
          this.improvementService.updateProjectsStatus(this.workshopId, 1)
            .subscribe(response => {
              console.log("updateProjectsStatus", response);
              this.socketImprovement.noProjectsSocket(this.workshopId, this.roomWorkShopId, 2);
            })
  
        }
      }
    }
  }

  hasProject1(){
    return new Promise(resolve => {
    this.improvementService.getImprovementsByWorkShopId(this.workshopId).subscribe(response => {
      console.log(response);
      response['improvements'][1]['options'].forEach(option => {
        if(option['Month'] == 3 && option['Option'] != 0 && option['Option'] != 6){
          resolve(true);
        }
      });
      resolve(false);

    });
  })

  }
  hasProject2(){
    return new Promise(resolve => {

    this.improvementService.getImprovementsByWorkShopId(this.workshopId).subscribe(response => {
      console.log(response);
      response['improvements'][1]['options'].forEach(option => {
        if(option['Month'] == 5 && option['Option'] != 0 && option['Option'] != 6){
          resolve(true);
        }
      });
      resolve(false);

    });
  })

  }
  async setPendingMonth(status) {
    return new Promise(async resolve => {
      if (status == 2 && this.showBtnImpOpt) {     
        console.log("continuamos con funcion")
        const hasProject1 = await this.hasProject1();
        const hasProject2 = await this.hasProject2();
        console.log(hasProject1, hasProject2)
        if (this.month == 5 && this.selectedOption == "0" && hasProject1) {
          console.log("caso1");
          this.isPendingProject = 5;
        } else if (this.month == 6 && this.selectedOption == "1" && hasProject1) {
          console.log("caso2");
          this.isPendingProject = 5;
        } else if (this.month == 6 && this.selectedOption == "0" && hasProject1) {
          console.log("caso3");
          this.isPendingProject = 0;
  
        }else if(this.month == 3 && this.selectedOption == "0" ){
          console.log("caso4");
          this.isPendingProject = 3;
        }else if(this.month == 4 && this.selectedOption == "1" && !hasProject1){
          console.log("caso5");
          this.isPendingProject = 3;
        }else if(this.month == 5 && this.selectedOption == "1" && hasProject1){
          console.log("caso6");
          this.isPendingProject = 0;
        } else if (this.month == 4 && this.selectedOption == "0" && !hasProject1) {
          console.log("caso7");
          this.isPendingProject = 0;
        } else if (this.month == 5 && this.selectedOption == "1" && !hasProject1) {
          console.log("caso8");
          this.isPendingProject = 3;
        } else if (this.month == 5 && this.selectedOption == "0" && !hasProject1) {
          console.log("caso9");
          this.isPendingProject = 5;
        }
        else if (this.month == 6 && this.selectedOption == "0" && !hasProject1) {
          console.log("caso10");
          this.isPendingProject = 0;

        } else if (this.month == 6 && this.selectedOption == "1" && !hasProject1) {
          console.log("caso11");
          this.isPendingProject = 3;
        }
        this.improvementService.updatePendingProjectByWorkShopId(this.workshopId, this.isPendingProject).subscribe(resp => {
          if (resp.intResponse == 200) {
            console.log("updatePendingProject OK")
            resolve(true)
          } else {
            resolve(false)
          }
        })
      }else{
        console.log("aun no ")
        resolve(true)
      }
    })
    //   if(this.month == 3 && this.selectedOption == "0"){
    //     this.isPendingProject = 3;
    //     console.log("caso1");
    //   }else if(this.month == 4 && !hasProject1){
    //     this.isPendingProject = 3;
    //     console.log("caso2");

    //   }else if(this.month == 5 && hasProject1 && this.isPendingProject != 0){
    //     this.isPendingProject = 0;

    //   }else if(this.month == 5 && !hasProject1){
    //     this.isPendingProject = 3;
    //   }else if(this.month == 5 && this.selectedOption == "0"){
    //       this.isPendingProject = 5;
    //   }else if(this.month == 6 && !hasProject1 ){
    //     this.isPendingProject = 3;
    //   }else if(this.month == 6 && !hasProject2){
    //     this.isPendingProject = 5;
    //   }
    //   console.log(this.isPendingProject);
    // }
  }
  async executeCodeCaseProjects(){
    try {
      await Promise.all(
        this.teams.map(async (team) => {
          let checkBoxes = []
          switch (this.WStype) {
            case "MFG":
              checkBoxes = [
                { id: 0, blnCheck: false, title: "Select Improvement Option", idBd: null, disabled: true, show: 1 },
                { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
                { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
                { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
                { id: 4, blnCheck: false, title: "Buy or Upgrade Equipment", idBd: null, disabled: true, show: 0 },
                { id: 5, blnCheck: false, title: "Pay Recruitment & Training", idBd: null, disabled: true, show: 0 },
                { id: 6, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
                { id: 7, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
                { id: 8, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 1 },
                { id: 9, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
                { id: 10, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
                { id: 11, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
                { id: 12, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
                { id: 13, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
                { id: 14, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 1 },
                { id: 15, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
                { id: 16, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
                { id: 17, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
                { id: 18, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }
              ];
              break;
            case "SS":
              checkBoxes = [
                { id: 0, blnCheck: false, title: "Select Improvement Option", idBd: null, disabled: true, show: 1 },
                { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
                { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
                { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
                { id: 4, blnCheck: false, title: "Buy or Upgrade Equipment", idBd: null, disabled: true, show: 0 },
                { id: 5, blnCheck: false, title: "Pay Recruitment & Training", idBd: null, disabled: true, show: 0 },
                { id: 6, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
                { id: 7, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
                { id: 8, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 0 },
                { id: 9, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
                { id: 10, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
                { id: 11, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
                { id: 12, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
                { id: 13, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
                { id: 14, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 0 },
                { id: 15, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
                { id: 16, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
                { id: 17, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
                { id: 18, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }
              ];
              break;
            default:
              break;
          }
  
          checkBoxes.forEach((item) => {
            item.blnCheck = false;
            item.disabled = true;
          });
          console.log("team"+team.teamId)
          // Await the asynchronous operation within the promise
          await this.boardService.createUpdCheckList(team.teamId, checkBoxes).subscribe(response => {
            console.log(response);
          });
          this.improvementService.getImprovementByTeamId(team.teamId).subscribe(response=>{
            console.log(response)
            let improveOptionId
            response['improvement']['options'].forEach(option => {
              if(option['Month'] === this.isPendingProject){
                improveOptionId = option['ImproveOptionId'];
              }
            });
            console.log(this.workshopId, improveOptionId,0,1, this.isPendingProject, 0)
            this.improvementService.updateImprovementOptionById(this.workshopId, improveOptionId,0,1, this.isPendingProject, 0).subscribe(resp =>{
              console.log("fnSave",resp);
              if(resp['intResponse'] === 200){
                // this.socketImprovementService.updateImproveOption(this.teamId,("roomWorkShop"+this.workshopId),6, improveOptionId, 1);

              }
            });
            
            this.socketService.updateStatusAPI('0', team.teamId, -1, this.month).subscribe(response => {
              if (response.intResponse == 200) {
                console.log("ok status")              } 
            });
          })
        })
      );
  
      // Code to execute after all team check lists are updated
      setTimeout(() => {
        console.log("All check lists updated successfully!");
        this.socketImprovement.noProjectsSocket(this.workshopId, this.roomWorkShopId, this.selectedOption);
      }, 1000);

      // Perform other actions here
    } catch (error) {
      console.error("Error updating check lists:", error);
      // Handle errors appropriately
    }
  }
  async executeCode() {
    try {
      await Promise.all(
        this.teams.map(async (team) => {
          let checkBoxes = []
          switch (this.WStype) {
            case "MFG":
              checkBoxes = [
                { id: 0, blnCheck: false, title: "Select Improvement Option", idBd: null, disabled: true, show: 0 },
                { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
                { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
                { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
                { id: 4, blnCheck: false, title: "Buy or Upgrade Equipment", idBd: null, disabled: true, show: 0 },
                { id: 5, blnCheck: false, title: "Pay Recruitment & Training", idBd: null, disabled: true, show: 0 },
                { id: 6, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
                { id: 7, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
                { id: 8, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 1 },
                { id: 9, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
                { id: 10, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
                { id: 11, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
                { id: 12, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
                { id: 13, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
                { id: 14, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 1 },
                { id: 15, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
                { id: 16, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
                { id: 17, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
                { id: 18, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }       
              ];
              break;
            case "SS":
              checkBoxes = [
                { id: 0, blnCheck: false, title: "Select Improvement Option", idBd: null, disabled: true, show: 0 },
                { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
                { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
                { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
                { id: 4, blnCheck: false, title: "Buy or Upgrade Equipment", idBd: null, disabled: true, show: 0 },
                { id: 5, blnCheck: false, title: "Pay Recruitment & Training", idBd: null, disabled: true, show: 0 },
                { id: 6, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
                { id: 7, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
                { id: 8, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 0 },
                { id: 9, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
                { id: 10, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
                { id: 11, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
                { id: 12, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
                { id: 13, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
                { id: 14, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 0 },
                { id: 15, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
                { id: 16, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
                { id: 17, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
                { id: 18, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }       
              ];
              break;
            default:
              break;
          } 
  
          checkBoxes.forEach((item) => {
            item.blnCheck = false;
            item.disabled = true;
          });
          console.log("team"+team.teamId)
          // Await the asynchronous operation within the promise
          await this.boardService.createUpdCheckList(team.teamId, checkBoxes).subscribe(response => {
            console.log(response);
          });
          this.improvementService.getImprovementByTeamId(team.teamId).subscribe(response=>{
            console.log(response)
            let improveOptionId
            response['improvement']['options'].forEach(option => {
              if(option['Month'] === this.month){
                improveOptionId = option['ImproveOptionId'];
              }
            });
            this.improvementService.updateImprovementOptionById(this.workshopId, improveOptionId,6,1, this.month, 0).subscribe(resp =>{
              console.log("fnSave",resp);
              if(resp['intResponse'] === 200){
                // this.socketImprovementService.updateImproveOption(this.teamId,("roomWorkShop"+this.workshopId),6, improveOptionId, 1);

              }
            });
            
            this.socketService.updateStatusAPI('0', team.teamId, 0, this.month).subscribe(response => {
              if (response.intResponse == 200) {
                console.log("ok status")              } 
            });
          })
        })
      );
  
      // Code to execute after all team check lists are updated
      setTimeout(() => {
        console.log("All check lists updated successfully!");
        this.socketImprovement.noProjectsSocket(this.workshopId, this.roomWorkShopId, this.selectedOption);
      }, 2000);

      // Perform other actions here
    } catch (error) {
      console.error("Error updating check lists:", error);
      // Handle errors appropriately
    }
  }
  fnAlertSuccess(strMessage){
    Swal.fire({
      text: '',
      imageUrl: '../../../../assets/iconSuccessfully.svg',
      imageAlt: 'icon successfully',
      confirmButtonColor: '#5EA0CC',
      html: `<div>${strMessage}</div>`,
    });
  }

  fnUpdateOpenMarketByWorkshopId(workshopId, blnOpen){
    this.orderService.updateOpenMarketByWorkshopId(workshopId, blnOpen)
    .subscribe(response=>{
      console.log("fnUpdateOpenMarketByWorkshopId", response);
    })
  }

  /*getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }*/

  getErrorLabel(labelNumber: string, code: string | null = null): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    let msg = ''
    if (tmp) {
      const LabelBase = tmp.LabelBase;
      let errorCode = code;
      if (!code) {
        const array = LabelBase.split(':');
        errorCode = array.length > 0 ? array[0] : '';
      }
      const className = environment.isProd ? 'lightgray' : 'gray';
      msg = `<div><span style="color:${className}; font-size: 12px">${errorCode}: </span>${tmp.Label}</div>`;
    } else if (labelNumber === '131-5013') {
      let errorCode = '1100';
      msg = `<div><span style="color:${environment.isProd ? 'lightgray' : 'gray'}; font-size: 12px">${errorCode}: </span>Start Month</div>`;
    } else if (labelNumber === '145-5013') {
      let errorCode = '1101';
      msg = `<div><span style="color:${environment.isProd ? 'lightgray' : 'gray'}; font-size: 12px">${errorCode}: </span>Stop Month</div>`;
    }
    return msg;
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this._data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }
  onOptionChange(event: Event){
    if(this.selectedOption == "0"){
      this.improvementStatus = 1
      this.socketImprovement.updateImproveStatus(
        this.workshopId,
        this.roomWorkShopId,
        this.improvementStatus,
        this.month
      );
    }
  }
  onCancel(): void {
    this.workshopService.setLogFac(this._data.teamId,this._data.userName,'Month By Month', 'Close', this._data.month).subscribe(res =>{ }) //Log for fac

    this.matDialogRef.close({
      status: this.improvementStatus
    });
  }
}
