import { _URL_BOARD } from './../../config/config';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { datosBalance, datosIncome } from 'src/app/views/board/board.component';

@Injectable({
  providedIn: 'root'
})
export class BoardService {

  constructor(private http: HttpClient) { }

  getInitBoard(teamId):Observable<any>  {
    return this.http.get(_URL_BOARD+'/getInitBoard/'+teamId);
  }

  updateMonth(teamId, month, status): Observable<any> {
    console.log("mes", month);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ teamId, month, status });
    return this.http.post(_URL_BOARD + '/updateMonth', params, {
      headers: httpHeaders,
    });
  }
  updateMonthAndStatus(teamId, month, status): Observable<any> {
    console.log("mes", month);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ teamId, month, status });
    return this.http.post(_URL_BOARD + '/updateMonthAndStatus', params, {
      headers: httpHeaders,
    });
  }

  updateCheckList(teamId, checkList): Observable<any> {
    console.log("checkList", checkList);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ teamId, checkList});
    return this.http.post(_URL_BOARD + '/updateCheckList', params, {
      headers: httpHeaders,
    });
  }

  createCoin(piece): Observable<any> {
    console.log("piece", piece);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({piece});
    return this.http.post(_URL_BOARD + '/createStack', params, {
      headers: httpHeaders,
    });
  }

  deletePiece(piece): Observable<any> {
    console.log("piece", piece);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({piece});
    return this.http.post(_URL_BOARD + '/deleteStack', params, {
      headers: httpHeaders,
    });
  }

  updatePiece(piece): Observable<any> {
    console.log("piece", piece);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({piece});
    return this.http.post(_URL_BOARD + '/updateStack', params, {
      headers: httpHeaders,
    });
  }

  updateMode(teamId, isModeOpen): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log(teamId, isModeOpen, "board service")
    const params = JSON.stringify({ teamId, isModeOpen });
    return this.http.post(_URL_BOARD + '/updateBoardMode', params, {
      headers: httpHeaders,
    });
  }
  
  getOrdersByTeamId(teamId, month): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log(teamId, month, "board service")
    const params = JSON.stringify({ teamId, month });
    return this.http.post(_URL_BOARD + '/getOrdersByTeamId', params, {
      headers: httpHeaders,
    });
  }

  getOrdersByWsandMonth(workshopId, month): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log(workshopId, month, "board service")
    const params = JSON.stringify({ workshopId, month });
    return this.http.post(_URL_BOARD + '/getOrdersByWSandMonth', params, {
      headers: httpHeaders,
    });
  }

  getBillStatus(teamId): Observable<any> {
    return this.http.get(_URL_BOARD+'/getBillStatus/'+teamId);
  }

  updateBillStatus(id, teamId, status, value): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const json = JSON.stringify({
      Id: id,
      TeamId: teamId,
      Status: status,
      Value: value
    });
    // const params = JSON.stringify(json);
    console.log('Si llegaaaaa', json, _URL_BOARD+'/updateBillStatus')
    return this.http.put(_URL_BOARD+'/updateBillStatus', json, {
      headers: httpHeaders,
    });
  }

  createUpdCheckList(teamId, checkList): Observable<any> {
    console.log("checkList", checkList);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ teamId, checkList });
    return this.http.post(_URL_BOARD + '/createCheckList', params, {
      headers: httpHeaders,
    });
  }


  getImprovements(teamId): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({ teamId });
    return this.http.post(_URL_BOARD + '/getImprovements', params, {
      headers: httpHeaders,
    });
  }

  updateMonthAndBoard(teamId: number, month: number): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
       teamId,
       month
      });
    return this.http.post(_URL_BOARD + '/updateMonthBoard', params, {
      headers: httpHeaders,
    });
  }

  getAdvertisingBill(teamId: number, month: number): Observable <any>{
    return this.http.get(_URL_BOARD + '/getAdvertisingBill/' + teamId+'/'+month);
  }

  getBoardsInfByWorkshopId(workshopId: number){
    return this.http.get(_URL_BOARD + '/getBoardsByWorkshopId/' + workshopId)
  }

  getTeamsChecklistByWorkshopId(workshopId: number){
    return this.http.get(_URL_BOARD + '/getTeamsChecklistByWorkshopId/' + workshopId)
  }
  getTeamChecklistByTeamId(teamId: number){
    return this.http.get(_URL_BOARD + '/GetCheckListByTeamId/' + teamId)
  }
  getFacilitatorIdTeamByTeamId(teamId: number){
    return this.http.get(_URL_BOARD + '/getFacilitatorIdTeamByTeamId/' + teamId)
  }
  getActionsBoardByTeamId(teamId: number): Observable <any>{
    return this.http.get(_URL_BOARD + '/getActionsBoard/' + teamId);
  }
  getFacLogs(team:string){ 
    console.log('consumimos getFacLogs')
    return this.http.get(_URL_BOARD+'/getFacLogs/'+team);
  }

  updateHistoryRoyals(workshopId: number, numberRoyals: number, teamId: number, month: number): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
        workshopId,
        numberRoyals,
        teamId,
        month
      });
    return this.http.post(_URL_BOARD + '/updateHistoryRoyal', params, {
      headers: httpHeaders,
    });
  }
  setSessionforUser(userId: number, logged: number, teamId:number): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
        userId,
        logged,
        teamId
      });
    return this.http.put(_URL_BOARD + '/usersession', params, {
      headers: httpHeaders,
    });
  }
  setSessionforTeam(teamId: number, logged: number): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
        teamId,
        logged
      });
    return this.http.put(_URL_BOARD + '/teamsession', params, {
      headers: httpHeaders,
    });
  }
 //  /api/general/board/usersession
  storeLiabilitiesReceivablesUpdates(workshopId: number, teamId: number): Observable<any> {
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', });
    const params = JSON.stringify({ workshopId, teamId, });
    return this.http.post(_URL_BOARD + '/history/liabilitiesReceivables', params, { headers: httpHeaders, });
  }

  getLiabilitiesReceivablesUpdates(workshopId: number, teamId: number): Observable<any> {
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', });
    return this.http.get(_URL_BOARD + `/history/liabilitiesReceivables/${workshopId}/${teamId}`, { headers: httpHeaders, });
  }

  clearLiabilitiesReceivablesUpdatesArea(
    workshopId: number,
    teamId: number,
    area: 'liabilities90' | 'liabilities60' | 'liabilities30' | 'receivables90' | 'receivables60' | 'receivables30' | 'payables60' | 'payables30'
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', });
    const params = JSON.stringify({ workshopId, teamId, area });
    return this.http.post(_URL_BOARD + `/history/liabilitiesReceivables/clear`, params, { headers: httpHeaders, });
  }

  updateBudgetLockStatus(workshopId: number, teamId: number , status: number){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
        workshopId,
        teamId, 
        status
      });
    return this.http.post(_URL_BOARD + '/updateBudgetLockStatus', params, {
      headers: httpHeaders,
    });
  }

  setBackBalanceAndIncome(idTeam, month, cantidadesBalance:datosBalance,cantidadesIncome:datosIncome): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      });
    const params = JSON.stringify({idTeam,month,cantidadesBalance,cantidadesIncome});
    console.log(params);
    return this.http.post(_URL_BOARD + '/setBack', params, {
      headers: httpHeaders,
    });
  }
  setBackBalanceAndIncomeCaseBuilding(idTeam, month): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      });
    const params = JSON.stringify({idTeam,month});
    console.log(params);
    return this.http.post(_URL_BOARD + '/setBackOfTeamCaseBuilding', params, {
      headers: httpHeaders,
    });
  }
  setBackBalanceAndIncomeCaseBank(idTeam, month): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      });
    const params = JSON.stringify({idTeam,month});
    console.log(params);
    return this.http.post(_URL_BOARD + '/setBackOfTeamCaseBank', params, {
      headers: httpHeaders,
    });
  }
  setBackBalanceAndIncomeCaseAdv(teamId,month,totSales,auxCash,auxReceibables,auxAdvertasting): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      });
    const params = JSON.stringify({teamId,month,totSales,auxCash,auxReceibables,auxAdvertasting});
    console.log(params);
    return this.http.post(_URL_BOARD + '/setBackOfTeamCaseAdvertasting', params, {
      headers: httpHeaders,
    });
  }
  setBackOfTeamCaseCogs(teamId,month,auxCogs): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      });
    const params = JSON.stringify({teamId,month,auxCogs});
    console.log(params);
    return this.http.post(_URL_BOARD + '/setBackOfTeamCaseCogs', params, {
      headers: httpHeaders,
    });
  }

  setBackOfTeamCasePayLoan(teamId,month,loan,caso): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      });
    const params = JSON.stringify({teamId,month,loan,caso});
    console.log(params);
    return this.http.post(_URL_BOARD + '/setBackOfTeamCasePayLoan', params, {
      headers: httpHeaders,
    });
  }
  getBackOfTeam(teamId: number, month: number): Observable<any> {
    return this.http.get(_URL_BOARD + `/getBackOfteam/${teamId}/${month}`);
  }
  goToMarketMonth1(teamId: number, workshopId: number){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
        teamId, workshopId
      });
    return this.http.post(_URL_BOARD + '/goToMarketMonth1', params, {
      headers: httpHeaders,
    });
  }
  goToFinishMonth1(teamId: number, workshopId: number){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
        teamId,
        workshopId
      });
    return this.http.post(_URL_BOARD + '/goToFinishMonth1', params, {
      headers: httpHeaders,
    });
  }

  getMarketStep(workshopId: number) {
    return this.http.get<{ intResponse: number, jsonData?: { MarketStep: number } }>(`${_URL_BOARD}/getMarketStep/${workshopId}`);
  }

  updateMarketStep(workshopId: number, marketStep: number, room: string) {
    const data = { workshopId, marketStep, room};
    return this.http.post<{ intResponse: number, jsonData?: { MarketStep: number } }>(`${_URL_BOARD}/updateMarketStep`, data);
  }

  getHistoryMaxSupply(
    workshopId: number,
    mes: number,
    teamId: number,
  ) {
    const data = { workshopId, mes, teamId };
    return this.http.post<{
      intResponse: number, jsonData?: {
        idhistoryMaxSupply: number,
        maxSupply: number,
        month: number,
        teamId: number,
        worksShopId: number,
        supply: number,
      }
    }>(`${_URL_BOARD}/getHistoryMaxSupply`, data);
  }

  storeHistoryMaxSupply(
    workshopId: number,
    mes: number,
    teamId: number,
    maxSupply: number,
    supply: number
  ) {
    const data = { workshopId, mes, teamId, maxSupply, supply };
    return this.http.post<{ intResponse: number, jsonData?: { MarketStep: number } }>(`${_URL_BOARD}/storeHistoryMaxSupply`, data);
  }

  getTeamLogs(room:string){ 
   return this.http.get(_URL_BOARD+'/getTeamLogs/'+room);
  }

  createBackOfCoin(idTeam, Month, AreaDrag,Valor,tipo): Observable<any> {
    //if area ==0 guardamos por default area de cash y retained earning
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({idTeam, Month, AreaDrag,Valor,tipo});
    return this.http.post(_URL_BOARD + '/createbackcoin', params, {
      headers: httpHeaders,
    });
  }


  GetBackOfCoins(idTeam, Month): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      });
    const params = JSON.stringify({idTeam, Month});
    return this.http.post(_URL_BOARD + '/getbackcoins', params, {
      headers: httpHeaders,
    });
  }

  updateFacilitatorMonth(idTeam, Month): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({idTeam, Month});
    return this.http.post(_URL_BOARD + '/updateFacilitatorMonth', params, {
      headers: httpHeaders,
    });
  }
  getFacilitatorMonth(idTeam): Observable<any> {
    return this.http.get(_URL_BOARD+'/getFacilitatorMonth/'+idTeam);
  }

  getTeamsStatusByWorkshopId(idWorkshop: number): Observable<any> {
    return this.http.get<any>(_URL_BOARD + '/getTeamsStatusByWorkshopId/' + idWorkshop);
}
  getTeamRoyals(idTeam): Observable<any> {
  return this.http.get(_URL_BOARD+'/getTeamRoyals/'+idTeam);
}

updateTeamRoyals(teamId, royals): Observable<any> {
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  const params = JSON.stringify({teamId, royals});
  return this.http.post(_URL_BOARD + '/updateTeamRoyals', params, {
    headers: httpHeaders,
  });
}
}
