import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { _URL_TIMEZONES, _URL_USERS } from './../../config/config';
import { _URL_WORKSHOP } from './../../config/config';
@Injectable({
  providedIn: 'root',
})
export class WorkshopService {

  private blnSwitchTable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public blnSwitchTable$ = this.blnSwitchTable.asObservable();
  
  constructor(private http: HttpClient) {}
  // this.DistSeleccionado[0].DistributorID,this.workshopForm.get('workshopName').value,
  // this.workshopForm.get('Client').value, this.FacilitatorSeleccionado, this.languageSeleccionado,
  // this.range.get('start').value, this.languageSeleccionado,
  // (this.range.get('end').value == null ? "" : this.range.get('end').value),TZSeleccionado, this.isResponseErrorEmailAlreadyRegister
  getContactsbyWorkshopId(id) {
    return this.http.get(_URL_WORKSHOP + '/contactsbyworkshopId/' + id);
  }

  createWorkShop(
    _DistributorID,
    _Name: string,
    _Client: string,
    _Facilitator: any[],
    _Language: any[],
    // _dateStart,
    // _endStart,
    tzSeleccionado,
    notes,
    businessModel,
    boardMode,
    cycles,
    _languageOrgPriv
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonWorkshop = {
      DistributorID: _DistributorID,
      Name: _Name,
      Client: _Client,
      Facilitator: 0,
      Language: _Language.length == 0 ? 0 : _Language[0].LanguageId,
      // StartDate: _dateStart,
      // EndDate: _endStart,
      TimeZone: tzSeleccionado.length == 0 ? 0 : tzSeleccionado[0].idtimeZones,
      Notes: notes,
      BusinessModel: businessModel,
      BoardMode: boardMode,
      Cycles: cycles,
      LanguageOrgPriv:_languageOrgPriv
    };
    const params = JSON.stringify(jsonWorkshop);
    console.log('Parametros a enviar WORKSHOP::::', params);
    return this.http.post(_URL_WORKSHOP + '/create', params, {
      headers: httpHeaders,
    });
  }

  updateWorkShop(
    _idWorkshop,
    _DistributorID,
    _Name: string,
    _Client: string,
    _Facilitator: any[],
    _Language: any[],
    // _dateStart,
    // _endStart,
    tzSeleccionado,
    notes,
    businessModel,
    BoardMode,
    cycles,
    _LanguageOrgPriv
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log("BUISNEEEEEES:::: ", businessModel[0].model);
    const jsonWorkshop = {
      WorkshopId: _idWorkshop,
      DistributorID: _DistributorID,
      Name: _Name,
      Client: _Client,
      Facilitator: _Facilitator.length == 0 ? 0 : _Facilitator[0].UserId,
      Language: _Language.length == 0 ? 0 : _Language[0].LanguageId,
      // StartDate: _dateStart,
      // EndDate: _endStart,
      TimeZone: tzSeleccionado.length == 0 ? 0 : tzSeleccionado[0].idtimeZones,
      Notes: notes,
      BusinessModel: businessModel,
      BoardMode,
      Cycles: cycles,
      LanguageOrgPriv: _LanguageOrgPriv
    };
    const params = JSON.stringify(jsonWorkshop);
    console.log('Parametros a enviar WORKSHOP::::', params);
    return this.http.post(_URL_WORKSHOP + '/update', params, {
      headers: httpHeaders,
    });
  }

  getAllTimeZones(): Observable<any> {
    return this.http.get(_URL_TIMEZONES + '/getAllTimeZones');
  }

  createWorkshopContact(_WorkshopID, _lstContacts: any[]): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonWorkshopcontact = {
      workshopID: _WorkshopID,
      arrayOfContacts: _lstContacts,
    };
    const params = JSON.stringify(jsonWorkshopcontact);
    console.log('Parametros a enviar WORKSHOP::::', params);
    return this.http.post(_URL_USERS + '/CreateContact', params, {
      headers: httpHeaders,
    });
  }

  searchWSCode(workshopId,code) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const json = {
      workshopId: workshopId,
      code: code,
    };
    const params = JSON.stringify(json);
    return this.http.post(_URL_USERS + '/searchWSCode', params, {
      headers: httpHeaders,
    });
  }

  createUpdateWorkshopFacilitators(
    _WorkshopID,
    _lstFacilitators: any[],
    boardType: string
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonWorkshopFacilitator = {
      workshopID: _WorkshopID,
      arrayOfFacilitators: _lstFacilitators,
      boardType: boardType
    };
    const params = JSON.stringify(jsonWorkshopFacilitator);
    console.log('Parametros a enviar WORKSHOP::::', params);
    return this.http.post(_URL_USERS + '/CreateUpdateFacilitators', params, {
      headers: httpHeaders,
    });
  }

  createUpdateDates(_WorkshopID, _lstDates: any[], isUpdate): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonWorkshopcontact = {
      workshopID: _WorkshopID,
      arrayDates: _lstDates,
    };
    const api = isUpdate ? '/updateDates':'/createDates';
    const params = JSON.stringify(jsonWorkshopcontact);
    console.log('Parametros a enviar WORKSHOP DATES::::', params);
    return this.http.post(_URL_WORKSHOP + api, params, {
      headers: httpHeaders,
    });
  }

  createDeliveryMails(_WorkshopID, minDate, templetes){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    templetes.forEach(item => {
      item.body = item.body.replace(/\n/gi,'<br>');
    });
    const jsonWorkshopcontact = {
      workshopID: _WorkshopID,
      DeliveryDate: minDate,
      templetes
    };
    // const api = isUpdate ? '/updateDates':'/createDates';
    const params = JSON.stringify(jsonWorkshopcontact);
    console.log('Parametros a enviar WORKSHOP DATES::::', params);
    return this.http.post(_URL_WORKSHOP + '/createDeliveryMails', params, {
      headers: httpHeaders,
    });
  }

  updateDateDeliveryMails(_WorkshopID, minDate){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonWorkshopcontact = {
      workshopID: _WorkshopID,
      DeliveryDate: minDate
    };
    const params = JSON.stringify(jsonWorkshopcontact);
    return this.http.post(_URL_WORKSHOP + '/updateDateDeliveryMails', params, {
      headers: httpHeaders,
    });
  }

  getDatesById(id) {
    console.log('id a envir::', id);
    return this.http.get(_URL_WORKSHOP + '/dates/' + id);
  }

  createUpdateSchedules(_WorkshopID, _lstSchedules: any[]): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonWorkshopcontact = {
      workshopID: _WorkshopID,
      arrayOfSchedules: _lstSchedules,
    };
    const params = JSON.stringify(jsonWorkshopcontact);
    console.log('Parametros a enviar WORKSHOP::::', params);
    return this.http.post(_URL_WORKSHOP + '/schedules', params, {
      headers: httpHeaders,
    });
  }

  fnCreateUpdateTimes(_WorkshopID, _lstTimes: any[]): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonWorkshopcontact = {
      workshopID: _WorkshopID,
      arrayOfTimes: _lstTimes,
    };
    const params = JSON.stringify(jsonWorkshopcontact);
    console.log('Parametros a enviar WORKSHOP::::', params);
    return this.http.post(_URL_WORKSHOP + '/times', params, {
      headers: httpHeaders,
    });
  }

  deleteWorkshopById(id): Observable<any> {
    return this.http.delete(`${_URL_WORKSHOP}/deleteWorkshopById/${id}`);
  }
  getWorkshopById(id) {
    return this.http.get(_URL_WORKSHOP + '/getWorkshopbyID/' + id);
  }

  getSchedulesById(id) {
    console.log('id a envir::', id);
    return this.http.get(_URL_WORKSHOP + '/schedules/' + id);
  }

  getTimesById(id) {
    return this.http.get(_URL_WORKSHOP + '/times/' + id);
  }

  getFacilitatorsById(id: number) {
    return this.http.get<any>(_URL_WORKSHOP + '/facilitators/' + id);
  }
  /*
  getAllWorkShops(type, idUser, idDistributor): Observable<any>{
    return this.http.get(_URL_WORKSHOP+'/getAllWorkshops/'+type/);
  }
*/
  getAllWorkShops(_type, _idUser, _idDistributor): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const jsonReq = {
      type: _type,
      idUser: _idUser,
      idDistributor: _idDistributor,
    };
    const params = JSON.stringify(jsonReq);
    console.log('Parametros a enviar WORKSHOP::::', params);
    return this.http.post(_URL_WORKSHOP + '/getAllWorkshops', params, {
      headers: httpHeaders,
    });
  }

  closeWorkshopByID(workshopId, status, date) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      status,
      lstDates: date,
    });
    return this.http.post(_URL_WORKSHOP + '/changeStatusWorkshop', params, {
      headers: httpHeaders,
    });
  }
  openTimeZone(workshopId, status) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      status,
    });
    console.log(params)
    return this.http.post(_URL_WORKSHOP + '/openTimeZone', params, {
      headers: httpHeaders,
    });
  }
  closeTimeZone(workshopId) {
    let status = 0;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      status
    });
    console.log(params)
    return this.http.post(_URL_WORKSHOP + '/openTimeZone', params, {
      headers: httpHeaders,
    });
  }
  getAllWorkshopsbyIdUser(id): Observable<any> {
    return this.http.get(
      _URL_WORKSHOP + '/getContactsAllWorkshopsbyUserID/' + id
    );
  }

  getCountOfMembersAndObserversbyWorkshop(idWorkshop) {
    return this.http.get(
      _URL_WORKSHOP + '/CountMembersAndObservers/' + idWorkshop
    );
  }

  blockDistributorByID(jsonUser) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(jsonUser);
    return this.http.post(_URL_WORKSHOP + '/blockDistributor', params, {
      headers: httpHeaders,
    });
  }

  fnSwitchTable(blnSwitchTable) {
    this.blnSwitchTable.next(blnSwitchTable);
  }
/*
  getExistsWorkshopBoard(idworkshop, currentdate) {
    return this.http.post(_URL_WORKSHOP + '/ExistsWorkshopBoard/'+idworkshop+ '/' + currentdate);
  }*/

  //Ajuste Validación SIN fechas
  //getExistsWorkshopBoard(idworkshop, currentdate, blnFacilitadorAux = false): Observable<any> {
  getExistsWorkshopBoard(idworkshop, blnFacilitadorAux = false): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({intIdWorkshop:idworkshop, blnFacilitador: blnFacilitadorAux});
    console.log(params)

    return this.http.post(_URL_WORKSHOP + '/ExistsWorkshopBoard', params, {
      headers: httpHeaders,
    });
  }
  getExistsTeamMeambersBoard(idworkshop): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({intIdWorkshop:idworkshop});
    console.log(params)

    return this.http.post(_URL_WORKSHOP + '/getExistsTeamMeambersBoard', params, {
      headers: httpHeaders,
    });
  }
  
  GetTimeZoneBoard(idworkshop): any {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({intIdWorkshop:idworkshop});
    return this.http.post(_URL_WORKSHOP + '/GetTimeZoneBoard', params, {
      headers: httpHeaders,
    });
  }
  

  createBackupBD(workshopId: number, month: number): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      workshopId,
      month
    });
    return this.http.post(_URL_WORKSHOP + '/createBackupBD', params, {
      headers: httpHeaders,
    });
  }

  setLogFac(teamId : number, userName : string, view : string, valor : string, month : number): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({
      teamId,
      userName,
      view,
      valor,
      month
    });
    return this.http.post(_URL_WORKSHOP + '/setLogFac', params, {
      headers: httpHeaders,
    });
  }

}
